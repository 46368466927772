import { Mesh } from "potree/geometry";
import { Euler, Sphere, Vector3 } from "potree/mathtypes";
import { Object3D } from "potree/object3d";
import { LineBasicMaterial, MeshBasicMaterial } from "potree/rendering/material";
import { getMousePointCloudIntersection } from "potree/utils/utils";
import { ToolBase } from "./base";
import { TRANSLATIONS } from "translation";
import { toast } from "react-toastify";
import { getAnnotations, postAnnotations } from "potree/skyqraft";
import { SQBoxBuffer, SQLineBuffer } from "potree/nova_renderer/buffer";


class Volume extends Object3D {
  showVolumeLabel = true;

  #clip = false;
  #modifiable = true;

  constructor(args = {}) {
    super();

    if (this.constructor.name === "Volume") {
      console.warn(
        "Can't create object of class Volume directly. Use classes BoxVolume instead."
      );
    }

    this.#clip = args.clip || false;
    this.#modifiable = args.modifiable || true;
    this._visible = true;
  }

  get visible() {
    return this._visible;
  }

  set visible(value) {
    if (this._visible !== value) {
      this._visible = value;
    }
  }

  getVolume() {
    console.warn("override this in subclass");
  }

  update() {}

  raycast(raycaster, intersects) {}

  get clip() {
    return this.#clip;
  }

  set clip(value) {
    if (this.#clip !== value) {
      this.#clip = value;

      this.update();
    }
  }

  get modifieable() {
    return this.#modifiable;
  }

  set modifieable(value) {
    this.#modifiable = value;

    this.update();
  }
}

class BoxVolume extends Volume {
  constructor(args = {}) {
    super(args);

    this.constructor.counter = (this.constructor.counter ?? 0) + 1;
    this.name = "box_" + this.constructor.counter;

    let boxGeometry = new SQBoxBuffer(1, 1, 1);
    boxGeometry.computeBoundingBox();

    let boxFrameGeometry = new SQLineBuffer([
      // bottom
      new Vector3(-0.5, -0.5, 0.5),
      new Vector3(0.5, -0.5, 0.5),
      new Vector3(0.5, -0.5, 0.5),
      new Vector3(0.5, -0.5, -0.5),
      new Vector3(0.5, -0.5, -0.5),
      new Vector3(-0.5, -0.5, -0.5),
      new Vector3(-0.5, -0.5, -0.5),
      new Vector3(-0.5, -0.5, 0.5),
      // top
      new Vector3(-0.5, 0.5, 0.5),
      new Vector3(0.5, 0.5, 0.5),
      new Vector3(0.5, 0.5, 0.5),
      new Vector3(0.5, 0.5, -0.5),
      new Vector3(0.5, 0.5, -0.5),
      new Vector3(-0.5, 0.5, -0.5),
      new Vector3(-0.5, 0.5, -0.5),
      new Vector3(-0.5, 0.5, 0.5),
      // sides
      new Vector3(-0.5, -0.5, 0.5),
      new Vector3(-0.5, 0.5, 0.5),
      new Vector3(0.5, -0.5, 0.5),
      new Vector3(0.5, 0.5, 0.5),
      new Vector3(0.5, -0.5, -0.5),
      new Vector3(0.5, 0.5, -0.5),
      new Vector3(-0.5, -0.5, -0.5),
      new Vector3(-0.5, 0.5, -0.5)
    ]);

    this.material = new MeshBasicMaterial({
      color: 0x00ff00,
      transparent: true,
      opacity: 0.3,
      depthTest: true,
      depthWrite: false,
    });
    this.box = new Mesh(boxGeometry, this.material);
    this.boundingBox = this.box.geometry.boundingBox;
    this.add(this.box);

    this.frame = new Mesh(
      boxFrameGeometry,
      new LineBasicMaterial({ color: 0x000000 })
    );
    this.add(this.frame);

    this.update();
  }

  update() {
    this.boundingBox = this.box.geometry.boundingBox;
    this.boundingSphere = this.boundingBox.getBoundingSphere(new Sphere());
  }

  raycast(raycaster, intersects) {
    let is = [];
    this.box.raycast(raycaster, is);

    if (is.length > 0) {
      let I = is[0];
      intersects.push({
        distance: I.distance,
        object: this,
        point: I.point.clone(),
      });
    }
  }

  getVolume() {
    return Math.abs(this.scale.x * this.scale.y * this.scale.z);
  }
}

class BoundingBox extends BoxVolume {
  isBoundingBox = true;
  #volumeTool = null;

  constructor(uuid = null, position, scale, rotation, volumeTool) {
    super({
      clip: true,
    });
    this.box.visible = false;

    this.#volumeTool = volumeTool;

    if (uuid !== null) {
      this.uuid = uuid;
    }

    this.position.copy(position);
    this.scale.copy(scale);

    const euler = new Euler(rotation.x, rotation.y, rotation.z, "XYZ");
    this.setRotationFromEuler(euler);

    this.addEventListener("dblclick", this.showTransformTool.bind(this));
  }

  get volumeTool() {
    return this.#volumeTool;
  }

  startDragging() {
    this.#volumeTool.viewer.inputHandler.startDragging(this);
    this.addEventListener("drag", this.drag);
    this.addEventListener("drop", this.drop);
  }

  drag = (e) => {
    const camera = this.#volumeTool.viewer.sceneContext.getActiveCamera();

    const i = getMousePointCloudIntersection(
      e.drag.end,
      this.#volumeTool.viewer.sceneContext.getActiveCamera(),
      this.#volumeTool.viewer,
      this.#volumeTool.viewer.sceneContext.pointclouds,
      { pickClipped: false }
    );

    if (i) {
      const wp = this.getWorldPosition(new Vector3()).applyMatrix4(
        camera.matrixWorldInverse
      );
      const w = Math.abs(wp.z / 5);

      this.scale.set(w, w, w);
      this.position.copy(i.location);
    }
  };

  drop = () => {
    this.removeEventListener("drag", this.drag);
    this.removeEventListener("drop", this.drop);
    this.#volumeTool.viewer.removeEventListener("cancel_insertions", this.drop);
  };

  showTransformTool() {
    this.#volumeTool.viewer.inputHandler.deselectAll();
    this.#volumeTool.viewer.inputHandler.toggleSelection(this);
  }

  updateFromDatabase(position, scale, rotation) {
    this.position.set(position);
    this.scale.set(scale);

    const euler = new Euler(rotation.x, rotation.y, rotation.z, "XYZ");
    this.setRotationFromEuler(euler);
  }
  serializeExtraData() {
    return {};
  }
}

export class AnnotationBox extends BoundingBox {
  isAnnotation = true;

  constructor(
    uuid = null,
    position,
    scale,
    rotation,
    volumeTool,
    annotation_type
  ) {
    super(uuid, position, scale, rotation, volumeTool);
    this.box_type = 9;

    this.annotation_type = annotation_type;
    this.frame.material.color.setHex(0xffffff);
  }

  setAnnotationType(type) {
    this.annotation_type = type;
    this.volumeTool.markDirty(this);
  }

  serializeExtraData() {
    return {
      annotation_type: Number(this.annotation_type),
    };
  }
}

export class ClassifierBox extends BoundingBox {
  isClassifier = true;

  type_from;
  #type_to;

  constructor(
    uuid = null,
    position,
    scale,
    rotation,
    volumeTool,
    type_from,
    type_to
  ) {
    super(uuid, position, scale, rotation, volumeTool);
    this.frame.material.color.setHex(0x92b2c7);

    this.type_from = type_from;
    this.#type_to = type_to;
    this.box_type = this.#type_to;
  }

  get type_to() {
    return this.#type_to;
  }
  set type_to(value) {
    this.#type_to = value;
    this.box_type = value;
  }

  serializeExtraData() {
    return {
      type_from: this.type_from,
      type_to: this.#type_to,
    };
  }
}

export class VolumeTool extends ToolBase {
  #dirtyVolumes = new Set();
  #removedVolumes = [];

  #annotationVolumes = [];
  #classificationVolumes = [];

  constructor(viewer) {
    super(viewer, "scene_volume", true);

    this.viewer.inputHandler.addEventListener("delete", (e) => {
      let volumes = e.selection.filter((e) => e instanceof Volume);
      volumes.forEach((e) => {
        this.viewer.sceneContext.removeVolume(e);
        this.scene.remove(e);
      });
    });

    viewer.addEventListener(
      "scene_context_changed",
      this.onSceneContextChange.bind(this)
    );

    viewer.sceneContext.addEventListener(
      "volume_removed",
      this.onRemove.bind(this)
    );
  }

  markDirty(volume) {
    this.#dirtyVolumes.add(volume);
  }

  onRemove(e) {
    this.scene.remove(e.volume);

    if (e.volume.from_database)
      // Only save removal of volumes from the DB.
      this.#removedVolumes.push(e.volume.uuid);

    this.#dirtyVolumes.delete(e.volume);
  }
  loadFromDatabase(e) {
    const scene = this.viewer.getScene();

    if (!window.confirm(TRANSLATIONS.EN.VOLUMES.ConfirmLoad)) {
      return;
    }

    getAnnotations(scene, this.viewer.sceneContext.signal)
      .then((response) => {
        for (const annotation of response.annotations) {
          const position = new Vector3(
            annotation.position_x,
            annotation.position_y,
            annotation.position_z
          );
          const scale = new Vector3(
            annotation.scale_x,
            annotation.scale_y,
            annotation.scale_z
          );
          const rotation = new Vector3(
            annotation.rotation_x,
            annotation.rotation_y,
            annotation.rotation_z
          );

          const existingBox = this.#annotationVolumes.find(
            (volume) => volume.uuid === annotation.uuid
          );
          if (!!existingBox) {
            // Update existing.
            existingBox.updateFromDatabase(position, scale, rotation);
          } else {
            // Create annotation.
            const volume = new AnnotationBox(
              annotation.id,
              position,
              scale,
              rotation,
              this,
              annotation.annotation_type
            );
            volume.from_database = true;
            this.#annotationVolumes.push(volume);

            this.viewer.sceneContext.addVolume(volume);
            this.scene.add(volume);
          }
        }

        for (const classifier of response.classifiers) {
          const position = new Vector3(
            classifier.position_x,
            classifier.position_y,
            classifier.position_z
          );
          const scale = new Vector3(
            classifier.scale_x,
            classifier.scale_y,
            classifier.scale_z
          );
          const rotation = new Vector3(
            classifier.rotation_x,
            classifier.rotation_y,
            classifier.rotation_z
          );

          // TODO: OMMT 2: O(new * existing) linear search is bad for performance.
          const existingBox = this.#classificationVolumes.find(
            (volume) => volume.uuid === classifier.id
          );
          if (!!existingBox) {
            existingBox.updateFromDatabase(position, scale, rotation);
          } else {
            const volume = new ClassifierBox(
              classifier.id,
              position,
              scale,
              rotation,
              this,
              classifier.type_from,
              classifier.type_to
            );
            volume.from_database = true;
            this.#classificationVolumes.push(volume);

            this.viewer.sceneContext.addVolume(volume);
            this.scene.add(volume);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  saveToDatabase() {
    if (this.viewer.isDemoMode()) {
      window.alert(TRANSLATIONS.EN.VOLUMES.DemoSaveWarning);

      toast.success(TRANSLATIONS.EN.VOLUMES.DemoSaveResponse);

      return;
    }

    if (this.#dirtyVolumes.size === 0 && this.#removedVolumes.length === 0) {
      window.alert(TRANSLATIONS.EN.VOLUMES.SaveNoChanges);
      return;
    }

    const annotation_map = [];
    const classifier_map = [];
    for (const volume of this.#dirtyVolumes.values()) {
      let pos = this.viewer.convertLidarToWSGS84(
        volume.position.x,
        volume.position.y,
        volume.position.z
      );

      let serialized_volume = {
        uuid: volume.uuid,
        lat: pos[0],
        lng: pos[1],
        position: { ...volume.position },
        scale: { ...volume.scale },
        rotation: {
          x: volume.rotation._x,
          y: volume.rotation._y,
          z: volume.rotation._z,
        },
        ...volume.serializeExtraData(),
      };

      if (volume instanceof AnnotationBox) {
        annotation_map.push(serialized_volume);
      } else if (volume instanceof ClassifierBox) {
        classifier_map.push(serialized_volume);
      } else {
        console.warn("Unhandled box type.");
      }
    }

    const scene = this.viewer.getScene();

    let confirmMessage = TRANSLATIONS.EN.VOLUMES.ConfirmSave;
    const updatedVolumesCount = annotation_map.length + classifier_map.length;
    if (updatedVolumesCount > 0)
      confirmMessage += `\n${updatedVolumesCount} volume(s) will be updated of ${this.viewer.sceneContext.volumes.length} in scene`;
    if (this.#removedVolumes.length > 0)
      confirmMessage += `\n${this.#removedVolumes.length} volume(s) will be removed.`;

    if (!window.confirm(confirmMessage)) {
      return;
    }

    postAnnotations(scene, this.#removedVolumes, annotation_map, classifier_map)
      .then((_) => {
        toast.success(TRANSLATIONS.EN.VOLUMES.SaveSuccess);

        for (const box of this.#dirtyVolumes) {
          box.from_database = true;
        }

        this.#dirtyVolumes.clear();
        this.#removedVolumes.length = 0;
      })
      .catch((err) => {
        toast.error(`${TRANSLATIONS.EN.VOLUMES.SaveFailure} <br>'${err}'`);
      });
  }

  onSceneContextChange(e) {
    e.sceneContext.addEventListener("volume_removed", this.onRemove.bind(this));

    this.#dirtyVolumes.clear();
    this.#removedVolumes.length = 0;

    this.#annotationVolumes.length = 0;
    this.#classificationVolumes.length = 0;
  }

  insertAnnotation() {
    let annotation = new AnnotationBox(
      null,
      new Vector3(),
      new Vector3(1, 1, 1),
      new Vector3(),
      this,
      null
    );
    this.#dirtyVolumes.add(annotation);

    this.#annotationVolumes.push(annotation);

    this.viewer.sceneContext.addVolume(annotation);
    this.scene.add(annotation);

    annotation.startDragging();
    annotation.showTransformTool();
  }

  insertClassifier(type) {
    let classifier = new ClassifierBox(
      null,
      new Vector3(),
      new Vector3(1, 1, 1),
      new Vector3(),
      this,
      null,
      type
    );
    this.#dirtyVolumes.add(classifier);

    this.viewer.sceneContext.addVolume(classifier);
    this.#classificationVolumes.push(classifier);
    this.scene.add(classifier);

    classifier.startDragging();
    classifier.showTransformTool();
  }
}
