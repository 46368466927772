import React from "react";
import ReactDOM from "react-dom";

import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HOCAuthenticator } from "./authentication";
import { Viewer } from "./potree/potree.js";
import { Menu } from "./react/sidebar";

const version = {
  major: 1,
  minor: 9,
  patch: 0,
};
console.log(
  "ARKION Lidar %d.%d.%d",
  version.major,
  version.minor,
  version.patch
);

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

class App extends React.Component {
  componentDidMount = () => {
    window.name = "sq-lidar";

    /*
     * This only works because we never remount this component.
     * It is an awful initialization because we need ``potree_render_area`` for Viewer but we need Viewer for the Menu.
     */
    const viewer = (window.viewer = new Viewer(
      document.getElementById("potree_render_area")
    ));
    viewer.loadSettingsFromSearchParams(params);
    viewer.setControls(viewer.earthControls);
    viewer.loadGlobalData();

    // This depends on both ``sidebars`` and Viewer.
    ReactDOM.render(
      <Menu start_open={!params?.scene} />,
      document.getElementById("sidebars")
    );

    let targetLocation = {
      type: "NONE",
    };
    if (params?.image) {
      const image_id = parseInt(params.image);

      targetLocation = {
        type: "IMAGE",
        image_id: image_id,
      };
    } else if (params?.lng && params?.lat) {
      targetLocation = {
        type: "COORD",
        lat: parseFloat(params.lat),
        lng: parseFloat(params.lng),
      };

      if (params?.alt) {
        targetLocation.alt = parseFloat(params.alt);
      }

      if (params?.pitch && params?.yaw) {
        targetLocation.with_direction = true;
        targetLocation.pitch = parseFloat(params.pitch);
        targetLocation.yaw = parseFloat(params.yaw);
      }
    }
    viewer.targetLocation = targetLocation;

    const cloud = window.location.pathname;
    const project = cloud.split("/")[1];

    if (cloud !== "/" && cloud !== "") {
      viewer.loadProjectData(project);
      if (params?.scene) {
        loadCloud(params.scene);
      }
    }

    async function loadCloud(scene) {
      await viewer.initializedPromise;

      viewer.loadScene(scene);
    }
  };

  render() {
    return (
      <>
        <div
          id="potree_render_area"
        >
          <div
            id="distance_class_wrapper"
            className="distance_class_panel_hidden"
          >
            <div id="distance_class_panel" />
          </div>
        </div>
        <div id="sidebars" />
        <ToastContainer newestOnTop theme="colored" />
      </>
    );
  }
}

export default HOCAuthenticator(App);
