// @ts-check

import { addLineNumbers } from "potree/utils/string.js";
import * as Shaders from "../rendering/shaders.js";

export class SQCache {
  /**
   *  Cache of compiled shader programs.
   */
  #shaderCache = new Map();

  #uniformCache = new Map();

  /**
   * @param {WebGL2RenderingContext} gl
   * @param {string} vert Vertex shader path
   * @param {string} frag Vertex shader path
   */
  getProgram(gl, vert, frag) {
    const key = `${vert} ${frag}`;

    const existingProgram = this.#shaderCache.get(key);
    if (existingProgram) {
      return existingProgram;
    }

    const program = this.#createProgram(gl, vert, frag);

    this.#shaderCache.set(key, program);

    return program;
  }

  /**
   * Returns a shader program with key `name`.
   *
   * Exists as an alternative with a "cheaper" hash key to `getProgram`.
   *
   * @param {WebGL2RenderingContext} gl
   * @param {string} name Unique key
   * @param {string} vert Vertex shader path
   * @param {string} frag Vertex shader path
   *
   * @returns {{program: WebGLProgram, uniforms: Map<string, WebGLUniformLocation>}}
   */
  getProgramNamed(gl, name, vert, frag) {
    const existingProgram = this.#shaderCache.get(name);
    if (existingProgram) {
      return {
        program: existingProgram,
        uniforms: this.#uniformCache.get(name),
      };
    }

    const program = this.#createProgram(gl, vert, frag);
    const uniforms = this.#extractUniforms(gl, program);

    this.#shaderCache.set(name, program);
    this.#uniformCache.set(name, uniforms);

    return {
      program,
      uniforms,
    };
  }

  /**
   * Returns all the uniforms & their location in the shader program.
   * This includes uniforms from both the vertex & fragment shader.
   *
   * @param {WebGL2RenderingContext} gl
   * @param {WebGLProgram} program
   *
   * @returns {Map<string, WebGLUniformLocation>}
   */
  #extractUniforms(gl, program) {
    const uniformCount = gl.getProgramParameter(program, gl.ACTIVE_UNIFORMS);

    const map = new Map();

    for (let i = 0; i < uniformCount; ++i) {
      const info = gl.getActiveUniform(program, i),
        addr = gl.getUniformLocation(program, info.name);

      map.set(info.name, addr);
    }

    return map;
  }

  /**
   * Compile a program with the provided vertex & fragment shaders.
   *
   * @param {WebGL2RenderingContext} gl
   * @param {string} vert Vertex shader path
   * @param {string} frag Vertex shader path
   */
  #createProgram(gl, vert, frag) {
    const program = gl.createProgram();

    const vertexShaderString = Shaders.get(vert);
    if (!vertexShaderString) {
      console.error(`No Vertex Shader found for: ${vert}`);
    }
    const fragmentShaderString = Shaders.get(frag);
    if (!fragmentShaderString) {
      console.error(`No Fragment Shader found for: ${frag}`);
    }

    const vertexShader = this.#createShader(
      gl,
      gl.VERTEX_SHADER,
      vertexShaderString,
    );
    const fragmentShader = this.#createShader(
      gl,
      gl.FRAGMENT_SHADER,
      fragmentShaderString,
    );

    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);

    gl.linkProgram(program);

    checkDiagnostics(gl, program, vertexShader, fragmentShader, vert, frag);

    // Clean up
    gl.deleteShader(vertexShader);
    gl.deleteShader(fragmentShader);
    return program;
  }

  /**
   * @param {WebGL2RenderingContext} gl
   * @param {number} type
   * @param {string} string
   */
  #createShader(gl, type, string) {
    const shader = gl.createShader(type);

    gl.shaderSource(shader, string);
    gl.compileShader(shader);

    return shader;
  }
}

/**
 * Checks for errors in compiling the shader program.
 *
 * @param {WebGL2RenderingContext} gl
 * @param {WebGLProgram} program
 * @param {WebGLShader} vertexShader
 * @param {WebGLShader} fragmentShader
 * @param {string} vertex_name
 * @param {string} fragment_name
 */
function checkDiagnostics(
  gl,
  program,
  vertexShader,
  fragmentShader,
  vertex_name,
  fragment_name,
) {
  const programLog = gl.getProgramInfoLog(program).trim();

  gl.validateProgram(program);
  if (!gl.getProgramParameter(program, gl.VALIDATE_STATUS)) {
    console.error(gl.getProgramInfoLog(program));
  }

  if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
    const vertexErrors = getShaderErrors(gl, vertexShader, "vertex");
    const fragmentErrors = getShaderErrors(gl, fragmentShader, "fragment");

    /**
     * All of our shaders are prewritten & no code is generated after publishing.
     * This is thus mostly useful for debugging locally. Any error here happens loudly during development.
     *
     * If this triggers in prod someone committed bad shaders.
     */
    console.error(
      "Shader error: ",
      gl.getError(),
      "\n[35715]:\n",
      gl.getProgramParameter(program, 35715),
      "\n[Log]:\n",
      programLog,
      "\n[Vertex Errors]:\n",
      vertexErrors,
      "\n[Fragment Errors]:\n",
      fragmentErrors,
    );
  } else if (programLog !== "") {
    // Warnings for the shader. Much like when writing any other code, these should be fixed but can be ignored.
    console.warn(
      `gl.getProgramInfoLog() (${vertex_name}, ${fragment_name}):`,
      programLog,
    );
  }
}

/**
 * @param {WebGL2RenderingContext} gl
 * @param {WebGLShader} shader
 * @param {string} shader_type
 */
function getShaderErrors(gl, shader, shader_type) {
  const status = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
  const log = gl.getShaderInfoLog(shader)?.trim() || "";

  if (status && !log) return "";

  // --enable-privileged-webgl-extension
  const source = gl.getShaderSource(shader);

  return `
      THREE.WebGLShader: gl.getShaderInfoLog() ${shader_type}
      ${log}
      ${addLineNumbers(source)}
  `;
}
