import { RGBAFormat, RGBFormat } from "./constants";
import { Texture } from "./types";

function loadImage(url) {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.onload = () => resolve(image);
    image.onerror = (error) => reject(error);

    image.src = url;
  });
}

export function loadTexture(url, onLoad, onError) {
  const texture = new Texture();

  loadImage(url)
    .then((image) => {
      texture.image = image;

      // JPEGs don't have an alpha channel, so store them as RGB.
      const isJPEG = /\.jpe?g($|\?)/i.test(url) || /^data:image\/jpeg/.test(url);
      texture.format = isJPEG ? RGBFormat : RGBAFormat;
      texture.needsUpdate = true;

      if (onLoad) {
        onLoad(texture);
      }
    })
    .catch((error) => {
      if (onError) {
        onError(error);
      }
    });

  return texture;
}