import { CONFIG_CONSTANTS } from "config";
import { Mesh } from "potree/geometry";
import { Matrix4, Quaternion, Vector3 } from "potree/mathtypes";
import { SQBoxBuffer, SQMeshLineBuffer } from "potree/nova_renderer/buffer";
import { Color } from "potree/rendering/types";
import { ToolBase } from "./base";
import * as Utils from "../utils/utils.js";

//ARKION CUSTOM
export class ImageObjectTool extends ToolBase {
  #imageGeometry = new SQBoxBuffer(1, 1, 0.05);
  #imageModelViewMatrixBuffer = null;

  #imageColor = new Color(1.0, 1.0, 1.0);
  #imageHoverColor = new Color(0.8, 0.8, 0.8);

  #imageDefectColor = new Color(1.0, 0.5, 0.5);
  #imageDefectHoverColor = new Color(0.8, 0.3, 0.3);

  #viewConeGeometry;
  // Don't like this.
  #viewConeGeometrySideOne;
  #viewConeGeometrySideTwo;
  #viewConeGeometrySideThree;
  #viewConeGeometrySideFour;

  #hoveredImage = -1;

  images = new Map();

  #imageProgram = null;

  #projectionMatrixLocation = null;
  #diffuseLocation = null;

  #lineProgram = null;

  constructor(viewer) {
    super(viewer, "scene_image_object", true);

    viewer.addEventListener("scene_context_changed", () => {
      this.images.clear();
    });

    const width = 0.5,
      depth = 5,
      t = 3;

    this.#viewConeGeometry = new SQMeshLineBuffer([
      // bottom
      new Vector3(width * t, -width * t, -depth),
      new Vector3(-width * t, -width * t, -depth),
      new Vector3(-width * t, -width * t, -depth),

      // top
      new Vector3(-width * t, width * t, -depth),
      new Vector3(-width * t, width * t, -depth),
      // sides
      new Vector3(width * t, width * t, -depth),
      new Vector3(width * t, width * t, -depth),
      new Vector3(width * t, -width * t, -depth),
    ]);

    this.#viewConeGeometrySideOne = new SQMeshLineBuffer([
      new Vector3(-width * t, width * t, -depth),
      new Vector3(-width + 0.01, width - 0.01, 0),
    ]);
    this.#viewConeGeometrySideTwo = new SQMeshLineBuffer([
      new Vector3(-width + 0.01, -width + 0.01, 0),
      new Vector3(-width * t, -width * t, -depth),
    ]);
    this.#viewConeGeometrySideThree = new SQMeshLineBuffer([
      new Vector3(width * t, -width * t, -depth),
      new Vector3(width - 0.01, -width + 0.01, 0),
    ]);
    this.#viewConeGeometrySideFour = new SQMeshLineBuffer([
      new Vector3(width - 0.01, width - 0.01, 0),
      new Vector3(width * t, width * t, -depth),
    ]);

    viewer.onLoadedRawSceneData.push((raw_data) => {
      const images = raw_data["images"];
      viewer.sceneData["images"] = images;

      viewer.sceneData["highest_image_alt"] = images.reduce(
        (accumulator, value) =>
          value["altitude"] > accumulator ? value["altitude"] : accumulator,
        0,
      );
    });

    viewer.addEventListener("scene_loaded", async () => {
      await viewer.epsgCallback;
      Object.values(viewer.sceneData["images"]).forEach((image) => {
        this.#addImageObject(
          image["project_id"],
          image["lng"],
          image["lat"],
          image["altitude"],
          image["pitch"] ?? CONFIG_CONSTANTS.FALLBACK_PITCH,
          image["compass_dir"],
          image["id"],
          image["defect_count"],
        );
      });

      if (viewer.targetLocation.type === "IMAGE") {
        const targetImage = viewer.targetLocation.image_id;
        this.goToImage(targetImage);

        // Not quite ready to publish but we don't have feature flags :(
        //setTimeout(this.#deadreckonGodBeamLocationFromImage.bind(this), 250, 0);
      }
    });

    viewer.initializedPromise.then(() => {
      const gl = this.viewer.gl;
      this.#imageProgram = this.viewer.shaderCache.getProgram(
        gl,
        "flat_mesh.vert",
        "flat_mesh.frag",
      );
      this.#lineProgram = this.viewer.shaderCache.getProgram(
        gl,
        "new_line.vert",
        "new_line.frag",
      );
      this.#imageGeometry.initializeVertexArrayObject(gl);
      this.#viewConeGeometry.initializeVertexArrayObject(gl);
      this.#viewConeGeometrySideOne.initializeVertexArrayObject(gl);
      this.#viewConeGeometrySideTwo.initializeVertexArrayObject(gl);
      this.#viewConeGeometrySideThree.initializeVertexArrayObject(gl);
      this.#viewConeGeometrySideFour.initializeVertexArrayObject(gl);

      this.#projectionMatrixLocation = gl.getUniformLocation(
        this.#imageProgram,
        "projectionMatrix",
      );
      this.#diffuseLocation = gl.getUniformLocation(
        this.#imageProgram,
        "diffuse",
      );
    });
  }

  /**
   * Attempt to find the point which the image points toward.
   *
   * ISSUE: This is correct for the data we get in but images aren't always correctly positioned or rotated relative to the point cloud :(
   */
  #deadreckonGodBeamLocationFromImage(attempt) {
    const image = this.images.get(this.viewer.targetLocation.image_id);

    // Cope the normal perspective camera.
    const camera = this.viewer.sceneContext.cameraP.clone();
    // Replace it's heading with the target image's.
    camera.position.set(image.position.x, image.position.y, image.position.z);
    camera.rotation.order = "ZXY";
    camera.rotation.set(image.rotation.x, image.rotation.y, image.rotation.z);
    camera.updateMatrixWorld();

    // Center of screen.
    const mouse = this.viewer.renderContext.size.clone().multiplyScalar(0.5);

    // Get the point at the mouse position (center of screen so the one right ahead in the middle).
    const I = Utils.getMousePointCloudIntersection(
      mouse,
      camera,
      this.viewer,
      this.viewer.sceneContext.pointclouds,
      { pickClipped: false },
    );

    if (I) {
      // Turn the position into a matrix and push it into the `cached_pos` of our targetLocation.
      // This is then rendered by lineRenderer.ts.
      const matrix = new Matrix4().compose(
        I.location,
        new Quaternion(),
        new Vector3(1.0, 1.0, 1.0),
      );
      this.viewer.targetLocation.cached_pos = matrix;
    } else if (attempt < 64) {
      // Let's not try forever.
      // Linearly increase the timeout time between attempts.
      // At most this goes to ~5 secs between before we give up.
      // In cases where the internet is extremely bad this might fail.
      setTimeout(
        this.#deadreckonGodBeamLocationFromImage.bind(this),
        250 * (1 + attempt / 3),
        attempt + 1,
      );
    }
  }

  renderImages() {
    if (this.active === false || this.images.size == 0) {
      return;
    }

    const gl = this.viewer.gl;
    gl.useProgram(this.#imageProgram);

    const camera = this.viewer.sceneContext.getActiveCamera();
    this.updateImageRenderMatrices(gl, camera.matrixWorldInverse);

    {
      // Set up render context.
      gl.disable(gl.BLEND);

      // Set camera projection matrix.
      gl.uniformMatrix4fv(
        this.#projectionMatrixLocation,
        false,
        camera.projectionMatrix.elements,
      );

      gl.bindVertexArray(this.#imageGeometry.vao);
      const indexCount = this.#imageGeometry.indices.length;
      gl.uniform3f(
        this.#diffuseLocation,
        this.#imageColor.r,
        this.#imageColor.g,
        this.#imageColor.b,
      );

      // Draw an instance using bound buffers.
      gl.drawElementsInstanced(
        gl.TRIANGLES,
        indexCount,
        gl.UNSIGNED_SHORT,
        0,
        this.images.size,
      );

      gl.bindVertexArray(null);
    }

    // Render view cone.
    const hovered_image = this.images.get(this.#hoveredImage);
    if (hovered_image) {
      gl.useProgram(this.#lineProgram);
      gl.disable(gl.CULL_FACE);

      const projectionMatrixLocation = gl.getUniformLocation(
        this.#lineProgram,
        "projectionMatrix",
      );
      gl.uniformMatrix4fv(
        projectionMatrixLocation,
        false,
        camera.projectionMatrix.elements,
      );
      const aspectLocation = gl.getUniformLocation(this.#lineProgram, "aspect");
      gl.uniform1f(aspectLocation, camera.aspect);

      const modelMatrixLocation = gl.getUniformLocation(
        this.#lineProgram,
        "modelViewMatrix",
      );
      const thicknessLocation = gl.getUniformLocation(
        this.#lineProgram,
        "thickness",
      );
      const diffuseLocation = gl.getUniformLocation(
        this.#lineProgram,
        "diffuse",
      );

      const thickness = 0.06;

      gl.uniformMatrix4fv(
        modelMatrixLocation,
        false,
        hovered_image.modelViewMatrix.elements,
      );
      gl.uniform1f(thicknessLocation, thickness);

      const color = hovered_image.color;
      gl.uniform4f(diffuseLocation, color.r, color.g, color.b, 1.0);

      this.viewer.lineRenderer.drawLines(gl, this.#viewConeGeometry);
      this.viewer.lineRenderer.drawLines(gl, this.#viewConeGeometrySideOne);
      this.viewer.lineRenderer.drawLines(gl, this.#viewConeGeometrySideTwo);
      this.viewer.lineRenderer.drawLines(gl, this.#viewConeGeometrySideThree);
      this.viewer.lineRenderer.drawLines(gl, this.#viewConeGeometrySideFour);
      gl.enable(gl.CULL_FACE);
    }
  }

  /**
   * Updates the matrixes on the GPU for images.
   *
   * @param {WebGL2RenderingContext} gl
   */
  updateImageRenderMatrices(gl, viewMatrix) {
    gl.bindVertexArray(this.#imageGeometry.vao);
    // The size of a matrix in floats.
    const matrixSize = 4 * 4;

    // Create the GPU buffer where we store the matrixes if it doesn't exist.
    if (!this.#imageModelViewMatrixBuffer) {
      this.#imageModelViewMatrixBuffer = gl.createBuffer();

      gl.bindBuffer(gl.ARRAY_BUFFER, this.#imageModelViewMatrixBuffer);

      /**
       * A matrix is 4x Vec4. Each of these Vec4s need their own attribute position.
       */
      const modelMatrixLoc = gl.getAttribLocation(
        this.#imageProgram,
        "modelViewMatrix",
      );
      for (let i = 0; i < 4; ++i) {
        const loc = modelMatrixLoc + i;
        gl.enableVertexAttribArray(loc);
        gl.vertexAttribPointer(loc, 4, gl.FLOAT, false, 64, i * matrixSize);
        gl.vertexAttribDivisor(loc, 1); // Advance each column per instance
      }
    }

    // Allocate an array that can fit all matrixes.
    // CONSIDERATION: It's likely more performant to create this once & just reallocate when the size changes.
    const modelViewMatrixes = new Float32Array(this.images.size * matrixSize);
    let i = 0;
    for (const [id, image] of this.images.entries()) {
      // Calculate the model view matrix.
      // We need to do this here because the GPU only supports 32 bit precision.
      // This is fine for many projects but on others it results in our geometry looking more like a hypercube than a regular shape.
      // Hence, 64 bit math on the CPU.
      image.modelViewMatrix.multiplyMatrices(viewMatrix, image.matrixWorld);

      // Copy matrix into the array.
      modelViewMatrixes.set(image.modelViewMatrix.elements, i * matrixSize);
      // Advance to the next matrix position.
      // This would be easier if we could enumerate the entries iterator.
      i += 1;
    }

    // Set that we are modifying this buffer.
    gl.bindBuffer(gl.ARRAY_BUFFER, this.#imageModelViewMatrixBuffer);

    // DYNAMIC_DRAW indicates that we will update this buffer often.
    // This indication is mainly for the GPU driver, allowing it to act smarter about the way the data is stored.
    gl.bufferData(gl.ARRAY_BUFFER, modelViewMatrixes, gl.DYNAMIC_DRAW);
  }

  goToImage(id) {
    const targetImage = this.images.get(id);
    if (!!targetImage) {
      const backPull = new Vector3(
        0,
        0,
        CONFIG_CONSTANTS.BACKPULL_DISTANCE,
      ).applyQuaternion(targetImage.quaternion);

      this.viewer.setCameraLocation(
        targetImage.position.x - backPull.x,
        targetImage.position.y - backPull.y,
        targetImage.position.z - backPull.z,
        -targetImage.rotation.x / (Math.PI / 180) +
          CONFIG_CONSTANTS.IMAGE_PITCH_CHANGE,
        -targetImage.rotation.z / (Math.PI / 180),
      );
    }
  }

  #addImageObject(projectId, lng, lat, alt, pitch, yaw, id, defect_count) {
    const has_defects = defect_count > 0;
    const hoverColor = has_defects
      ? this.#imageDefectHoverColor
      : this.#imageHoverColor;
    const regularColor = has_defects
      ? this.#imageDefectColor
      : this.#imageColor;

    const imageObject = new Mesh(this.#imageGeometry, null);
    imageObject.color = regularColor;

    imageObject.addEventListener("mouseover", () => {
      this.#hoveredImage = id;
      imageObject.color = hoverColor;
    });

    imageObject.addEventListener("mouseleave", () => {
      this.#hoveredImage = -1;
      imageObject.color = regularColor;
    });

    imageObject.addEventListener("dblclick", () =>
      this.viewer.openImageInMap(projectId, id),
    );

    const transformedPos = this.viewer.convertWGS84toLidar(lng, lat, alt);
    // Update view position & rotation with transformed positions & recieved rotations.
    imageObject.position.set(
      transformedPos[0],
      transformedPos[1],
      transformedPos[2],
    );
    imageObject.rotation.order = "ZXY";
    imageObject.rotation.set(
      -pitch * (Math.PI / 180),
      0,
      -yaw * (Math.PI / 180),
    );

    imageObject.updateMatrixWorld();
    this.scene.add(imageObject);

    this.images.set(id, imageObject);
    this.updateInstanceMatrixes = true;
  }
}
