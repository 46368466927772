import BaseControls from "./BaseControls";
import { Vector3 } from "../mathtypes.js";
import { MOUSE } from "../constants";

export default class FirstPersonControls extends BaseControls {
  #yawDelta = 0;
  #pitchDelta = 0;
  #translationDelta = new Vector3(0, 0, 0);
  #translationWorldDelta = new Vector3(0, 0, 0);
  keys = {
    FORWARD: ["W".charCodeAt(0), 38],
    BACKWARD: ["S".charCodeAt(0), 40],
    LEFT: ["A".charCodeAt(0), 37],
    RIGHT: ["D".charCodeAt(0), 39],
    UP: ["R".charCodeAt(0), 33],
    DOWN: ["F".charCodeAt(0), 34],
  };
  lockElevation = false;

  constructor(viewer) {
    super(viewer);
    this.rotationSpeed = 200;
  }

  onDrag(e) {
    if (e.drag.object !== null) {
      return;
    }

    if (e.drag.startHandled === undefined) {
      e.drag.startHandled = true;

      this.dispatchEvent({ type: "start" });
    }

    const moveSpeed = this.viewer.getMoveSpeed();
    const domElement = this.viewer.renderContext.canvas;

    const ndrag = {
      x: e.drag.lastDrag.x / domElement.clientWidth,
      y: e.drag.lastDrag.y / domElement.clientHeight,
    };

    if (e.drag.mouse === MOUSE.LEFT) {
      this.#yawDelta += ndrag.x * this.rotationSpeed;
      this.#pitchDelta += ndrag.y * this.rotationSpeed;
    } else if (e.drag.mouse === MOUSE.RIGHT) {
      this.#translationDelta.x -= ndrag.x * moveSpeed * 100;
      this.#translationDelta.z += ndrag.y * moveSpeed * 100;
    }
    this.viewer.sceneContext.view.dirty();
  }

  onScroll(e) {
    let speed = this.viewer.getMoveSpeed();

    if (e.delta < 0) {
      speed = speed * 0.9;
    } else if (e.delta > 0) {
      speed = speed / 0.9;
    }

    speed = Math.min(Math.max(speed, 1.0), 10_001.0);

    this.viewer.setMoveSpeed(speed);
  }

  stop() {
    this.#yawDelta = 0;
    this.#pitchDelta = 0;
    this.#translationDelta.set(0, 0, 0);
  }

  update(delta) {
    const view = this.sceneContext.view;

    {
      // cancel move animations on user input
      const changes = [
        this.#yawDelta,
        this.#pitchDelta,
        this.#translationDelta.length(),
        this.#translationWorldDelta.length(),
      ];
      const changeHappens = changes.some((e) => Math.abs(e) > 0.001);
      if (changeHappens && this.tweens.length > 0) {
        this.stopTweens();
      }
    }

    {
      // accelerate while input is given
      const inputHandler = this.viewer.inputHandler;

      const moveForward = this.keys.FORWARD.some((e) =>
        inputHandler.isPressed(e)
      );
      const moveBackward = this.keys.BACKWARD.some((e) =>
        inputHandler.isPressed(e)
      );
      const moveLeft = this.keys.LEFT.some((e) => inputHandler.isPressed(e));
      const moveRight = this.keys.RIGHT.some((e) => inputHandler.isPressed(e));
      const moveUp = this.keys.UP.some((e) => inputHandler.isPressed(e));
      const moveDown = this.keys.DOWN.some((e) => inputHandler.isPressed(e));

      if (this.lockElevation) {
        const dir = view.direction;
        dir.z = 0;
        dir.normalize();

        if (moveForward && moveBackward) {
          this.#translationWorldDelta.set(0, 0, 0);
        } else if (moveForward) {
          this.#translationWorldDelta.copy(
            dir.multiplyScalar(this.viewer.getMoveSpeed())
          );
        } else if (moveBackward) {
          this.#translationWorldDelta.copy(
            dir.multiplyScalar(-this.viewer.getMoveSpeed())
          );
        }
      } else {
        if (moveForward && moveBackward) {
          this.#translationDelta.y = 0;
        } else if (moveForward) {
          this.#translationDelta.y = this.viewer.getMoveSpeed();
        } else if (moveBackward) {
          this.#translationDelta.y = -this.viewer.getMoveSpeed();
        }
      }

      if (moveLeft && moveRight) {
        this.#translationDelta.x = 0;
      } else if (moveLeft) {
        this.#translationDelta.x = -this.viewer.getMoveSpeed();
      } else if (moveRight) {
        this.#translationDelta.x = this.viewer.getMoveSpeed();
      }

      if (moveUp && moveDown) {
        this.#translationWorldDelta.z = 0;
      } else if (moveUp) {
        this.#translationWorldDelta.z = this.viewer.getMoveSpeed();
      } else if (moveDown) {
        this.#translationWorldDelta.z = -this.viewer.getMoveSpeed();
      }
    }

    // apply rotation
    view.yaw -= this.#yawDelta * delta;
    view.pitch -= this.#pitchDelta * delta;
    const zeroVector = new Vector3(0, 0, 0);
    if (
      this.#translationDelta !== zeroVector ||
      this.#translationWorldDelta !== zeroVector
    ) {
      // apply translation
      view.translate(
        this.#translationDelta.x * delta,
        this.#translationDelta.y * delta,
        this.#translationDelta.z * delta
      );

      view.translateWorld(
        this.#translationWorldDelta.x * delta,
        this.#translationWorldDelta.y * delta,
        this.#translationWorldDelta.z * delta
      );

      view.dirty();
    }

    // set view target according to speed
    view.radius = 3 * this.viewer.getMoveSpeed();

    // decelerate over time
    const attenuation = Math.max(0, 1 - this.fadeFactor * delta);
    this.#yawDelta *= attenuation;
    this.#pitchDelta *= attenuation;
    this.#translationDelta.multiplyScalar(attenuation);
    this.#translationWorldDelta.multiplyScalar(attenuation);
  }
}
