//@ts-check

import { Box3, Sphere, Vector3 } from "potree/mathtypes";
import { clamp, generateUUID } from "potree/utils/math";

export class SQBuffer {
  uuid = generateUUID();

  vertices: Float32Array;

  /**
   * Buffer that the vertex data is stored in on the GPU.
   */
  verticesBuffer: WebGLBuffer | null;

  indices: Uint16Array;

  normals: Float32Array;

  uvs: Float32Array;

  /**
   * Vertex Array Object. Stores set up for rendering the buffer geometry, such as which vertex attributes exist.
   */
  vao: WebGLVertexArrayObject = null;

  /**
   * If we should update our buffers the next time we are rendered.
   *
   * Used by Measures to update the lines drawn between points.
   *
   */
  needsBufferUpdate = false;

  /**
   * Spherical bounds of the geometry in local space. Needed for filtering out interactive objects in a space.
   */
  boundingSphere: Sphere = null;

  /**
   * Axis-Aligned bounding box covering the geometry in local space.
   * Used for interactive objects somewhere.
   */
  boundingBox: Box3 = null;

  /**
   * Whether this geometry has indicies or not.
   *
   * Without indices the geometry will be drawn using gl.drawArrays, this is useful for drawing lines else you should stick with having indices.
   */
  hasIndices = true;

  /**
   *   Create & upload the GPU data for this geometry.
   */
  initializeVertexArrayObject(gl: WebGL2RenderingContext) {
    // Vertex array manages the context for the rest of the data.
    const vertexArray = gl.createVertexArray();
    gl.bindVertexArray(vertexArray);

    // Bind positions.
    const positionsBuffer = gl.createBuffer(); // The buffer is essentially just an Id.
    gl.bindBuffer(gl.ARRAY_BUFFER, positionsBuffer);
    // @ts-ignore
    gl.bufferData(gl.ARRAY_BUFFER, this.vertices, gl.STATIC_DRAW);

    const positionAttributeLocation = 0;
    gl.enableVertexAttribArray(positionAttributeLocation);
    gl.vertexAttribPointer(
      positionAttributeLocation,
      3,
      gl.FLOAT,
      false,
      0,
      0
    );

    const normalsBuffer = gl.createBuffer(); // The buffer is essentially just an Id.
    gl.bindBuffer(gl.ARRAY_BUFFER, normalsBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, this.normals, gl.STATIC_DRAW);

    const normalAttributeLocation = 1;
    gl.enableVertexAttribArray(normalAttributeLocation);
    gl.vertexAttribPointer(
      normalAttributeLocation,
      3,
      gl.FLOAT,
      false,
      0,
      0
    );

    const uvsBuffer = gl.createBuffer(); // The buffer is essentially just an Id.
    gl.bindBuffer(gl.ARRAY_BUFFER, uvsBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, this.uvs, gl.STATIC_DRAW);

    const uvAttributeLocation = 2;
    gl.enableVertexAttribArray(uvAttributeLocation);
    gl.vertexAttribPointer(
      uvAttributeLocation,
      2,
      gl.FLOAT,
      false,
      0,
      0
    );

    // All the indices (These are groups of 3 that make up triangles)
    const indexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
    gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, this.indices, gl.STATIC_DRAW);

    gl.bindBuffer(gl.ARRAY_BUFFER, null);
    gl.bindVertexArray(null);

    this.vao = vertexArray;
    this.verticesBuffer = positionsBuffer;
    this.needsBufferUpdate = false;
  }

  /**
   * Update GPU buffers if they exist.
   */
  updateBuffers(gl: WebGL2RenderingContext) {
    if(this.verticesBuffer) {
      gl.bindBuffer(gl.ARRAY_BUFFER, this.verticesBuffer);

      gl.bufferData(gl.ARRAY_BUFFER, this.vertices, gl.STATIC_DRAW);
    }

    this.needsBufferUpdate = false;
  }

  computeBoundingSphere() {
    // The max distance from the center of any point (squared).
    let maxDistanceSqr = 0.0;


    for(let i = 0; i < this.vertices.length; i += 3) {
      const x = this.vertices[i];
      const y = this.vertices[i + 1];
      const z = this.vertices[i + 2];

      // Pythagorean Theorem.
      // This is the usual distance formula from Maths class.
      // Note: This is the square distance, we don't do square root until necessary for performance.
      const distanceFromCenter = x*x + y*y + z*z;
      if (distanceFromCenter > maxDistanceSqr) {
        maxDistanceSqr = distanceFromCenter;
      }
    }

    this.boundingSphere = new Sphere(new Vector3(0.0,0.0,0.0), Math.sqrt(maxDistanceSqr));
  }

  computeBoundingBox() {
    // The min & max of all axis.
    let min = new Vector3(0.0, 0.0, 0.0);
    let max = new Vector3(0.0, 0.0, 0.0);


    for(let i = 0; i < this.vertices.length; i += 3) {
      const x = this.vertices[i];
      const y = this.vertices[i + 1];
      const z = this.vertices[i + 2];

      if(x < min.x) {
        min.x = x;
      }
      if(y < min.y) {
        min.y = y;
      }
      if(z < min.z) {
        min.z = z;
      }
      if(x > max.x) {
        max.x = x;
      }
      if(y > max.y) {
        max.y = y;
      }
      if(z > max.z) {
        max.z = z;
      }
    }

    this.boundingBox = new Box3(min, max);
  }
}

export class SQMeshLineBuffer extends SQBuffer {
  count = 0;
  direction: Float32Array;
  previous: Float32Array;
  next: Float32Array;

  constructor(
    points: Vector3[]
  ) {
    super();

    this.direction = new Float32Array(this.#duplicateMirror(points.map(() => 1)));
    this.count = (points.length - 1) * 6;

    this.vertices = new Float32Array(points.flatMap(({ x, y, z }) => [x, y, z, x, y, z]));

    const previous = points.map(this.#relative(-1));
    this.previous = new Float32Array(previous.flatMap(({ x, y, z }) => [x, y, z, x, y, z]));

    const next = points.map(this.#relative(+1));
    this.next = new Float32Array(next.flatMap(({ x, y, z }) => [x, y, z, x, y, z]));

    this.indices = this.#createIndices(points.length);
  }

  #duplicateMirror(array) {
    return array.flatMap((element) => [element, -element]);
  }
  #relative(offset) {
    return (_point, index, list) => {
      index = clamp(index + offset, 0, list.length-1)
      return list[index]
    }
  }

  #createIndices(length: number) {
    let indices = new Uint16Array(length * 6)
    let c = 0, index = 0
    for (let j=0; j<length; j++) {
      let i = index
      indices[c++] = i + 0
      indices[c++] = i + 1
      indices[c++] = i + 2
      indices[c++] = i + 2
      indices[c++] = i + 1
      indices[c++] = i + 3
      index += 2
    }
    return indices
  }

  initializeVertexArrayObject(gl: WebGL2RenderingContext) {
    const vertexArray = gl.createVertexArray();
    gl.bindVertexArray(vertexArray);

    this.#createAttribute(gl, this.vertices, 0, 3);
    this.#createAttribute(gl, this.direction, 1, 1);
    this.#createAttribute(gl, this.next, 2, 3);
    this.#createAttribute(gl, this.previous, 3, 3);

    const indexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
    gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, this.indices, gl.STATIC_DRAW);

    gl.bindBuffer(gl.ARRAY_BUFFER, null);
    gl.bindVertexArray(null);

    this.vao = vertexArray;
  }

  #createAttribute(gl: WebGL2RenderingContext, attribute: Float32Array, location: number, attributeSize: number) {
    let buffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
    gl.bufferData(gl.ARRAY_BUFFER, attribute, gl.STATIC_DRAW);

    gl.vertexAttribPointer(
      location,
      attributeSize,
      gl.FLOAT,
      false,
      0,
      0
    );
    gl.enableVertexAttribArray(location);

    return {
      buffer,
      attribute,
      location
    };
  }
}


export class SQLineBuffer extends SQBuffer {
  constructor(
    points: Vector3[]
  ) {
    super();

    this.vertices = new Float32Array(points.flatMap(({x, y, z}) => [x,y,z]));
    this.hasIndices = false;
  }

  // @ts-ignore
  initializeVertexArrayObject(gl: WebGL2RenderingContext) {
    const vertexArray = gl.createVertexArray();
    gl.bindVertexArray(vertexArray);

    // Create buffer for positions.
    const positionsBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, positionsBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, this.vertices, gl.STATIC_DRAW);

    // Assign buffer to attribute location 0.
    const attributeLocation = 0;
    gl.vertexAttribPointer(
      attributeLocation,
      3,
      gl.FLOAT,
      false,
      0,
      0
    );
    gl.enableVertexAttribArray(attributeLocation);

    gl.bindBuffer(gl.ARRAY_BUFFER, null);
    gl.bindVertexArray(null);

    this.vao = vertexArray;
    this.verticesBuffer = positionsBuffer;
  }
}


export class SQBoxBuffer extends SQBuffer {

  constructor(
    width = 1,
    height = 1,
    depth = 1
  ) {
    super();

    const half_width = width / 2.0;
    const half_height = height / 2.0;
    const half_depth = depth / 2.0;

    const vertices = [
      -half_width, half_height, -half_depth,
      half_width, half_height, -half_depth,
      half_width, -half_height, -half_depth,
      -half_width, -half_height, -half_depth,

      -half_width, half_height, half_depth,
      half_width, half_height, half_depth,
      half_width, -half_height, half_depth,
      -half_width, -half_height, half_depth
    ];
    this.vertices = new Float32Array(vertices);

    this.indices = new Uint16Array([
      // BACK
      0,1,2,
      0,2,3,

      // LEFT
      0,7,4,
      0,3,7,

      // RIGHT
      5,2,1,
      5,6,2,

      // BOTTOM
      3,2,6,
      3,6,7,

      // TOP
      0,5,1,
      0,4,5,

      // FRONT
      4,6,5,
      4,7,6,
    ]); // 2 for each side.

    this.boundingSphere = new Sphere(new Vector3(), Math.max(half_depth, half_height, half_width));
    this.boundingBox = new Box3(new Vector3(-half_width, -half_height, -half_depth), new Vector3(half_width, half_height, half_depth));
  }

  initializeVertexArrayObject(gl: WebGL2RenderingContext) {
    // Vertex array manages the context for the rest of the data.
    const vertexArray = gl.createVertexArray();
    gl.bindVertexArray(vertexArray);

    // All the vertices of the mesh.
    const vertices = this.vertices;

    const positionsBuffer = gl.createBuffer(); // The buffer is essentially just an Id.
    gl.bindBuffer(gl.ARRAY_BUFFER, positionsBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW);

    // All the incides (These are groups of 3 that make up triangles)
    const indexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
    gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, this.indices, gl.STATIC_DRAW);

    const attributeLocation = 0;
    gl.vertexAttribPointer(
      attributeLocation,
      3,
      gl.FLOAT,
      false,
      0,
      0
    );
    gl.enableVertexAttribArray(attributeLocation);

    gl.bindBuffer(gl.ARRAY_BUFFER, null);
    gl.bindVertexArray(null);

    this.vao = vertexArray;
    this.verticesBuffer = positionsBuffer;
  }
}

export class SQSphereBuffer extends SQBuffer {
  constructor(
    radius = 1,
    widthSegments = 8,
    heightSegments = 6,
    phiStart = 0,
    phiLength = Math.PI * 2,
    thetaStart = 0,
    thetaLength = Math.PI
  ) {
    super();

    widthSegments = Math.max(3, Math.floor(widthSegments));
    heightSegments = Math.max(2, Math.floor(heightSegments));

    const thetaEnd = Math.min(thetaStart + thetaLength, Math.PI);

    let index = 0;
    const grid = [];

    const vertex = new Vector3();
    const normal = new Vector3();

    const indices = [];
    const vertices = [];
    const normals = [];
    const uvs = [];

    // generate vertices, normals and uvs


    for (let iy = 0; iy <= heightSegments; iy++) {
      const verticesRow = [];

      const v = iy / heightSegments;

      // special case for the poles

      let uOffset = 0;

      if (iy === 0 && thetaStart === 0) {
        uOffset = 0.5 / widthSegments;
      } else if (iy === heightSegments && thetaEnd === Math.PI) {
        uOffset = -0.5 / widthSegments;
      }

      for (let ix = 0; ix <= widthSegments; ix++) {
        const u = ix / widthSegments;

        // vertex

        vertex.x =
          -radius *
          Math.cos(phiStart + u * phiLength) *
          Math.sin(thetaStart + v * thetaLength);
        vertex.y = radius * Math.cos(thetaStart + v * thetaLength);
        vertex.z =
          radius *
          Math.sin(phiStart + u * phiLength) *
          Math.sin(thetaStart + v * thetaLength);

        vertices.push(vertex.x, vertex.y, vertex.z);

        // normal

        normal.copy(vertex).normalize();
        normals.push(normal.x, normal.y, normal.z);

        // uv

        uvs.push(u + uOffset, 1 - v);

        verticesRow.push(index++);
      }

      grid.push(verticesRow);
    }

    // indices

    for (let iy = 0; iy < heightSegments; iy++) {
      for (let ix = 0; ix < widthSegments; ix++) {
        const a = grid[iy][ix + 1];
        const b = grid[iy][ix];
        const c = grid[iy + 1][ix];
        const d = grid[iy + 1][ix + 1];

        if (iy !== 0 || thetaStart > 0) indices.push(a, b, d);
        if (iy !== heightSegments - 1 || thetaEnd < Math.PI)
          indices.push(b, c, d);
      }
    }

    this.indices = new Uint16Array(indices);
    this.vertices = new Float32Array(vertices);
    this.normals = new Float32Array(normals);
    this.uvs = new Float32Array(uvs);
  }
}


export class SQPlaneBuffer extends SQBuffer {

  constructor(
    width: number,
    height: number
  ) {
    super();

    const half_width = width / 2.0;
    const half_height = height / 2.0;

    // generate vertices, normals and uvs
    this.vertices = new Float32Array([
      -half_width, -half_height, 0,
      half_width, -half_height, 0,
      -half_width, half_height, 0,
      half_width, half_height, 0,
    ]);
    this.indices = new Uint16Array([
      0, 1, 2,
      2, 1, 3
    ]);
    this.normals = new Float32Array([
      0, 0, 1,
      0, 0, 1,
      0, 0, 1,
      0, 0, 1
    ]);
    this.uvs = new Float32Array([
      0, 0,
      1, 0,
      0, 1,
      1, 1
    ]);
  }
}

export class SQTorusBuffer extends SQBuffer {
  constructor(
    radius = 1,
    tube = 0.4,
    radialSegments = 8,
    tubularSegments = 6,
    arc = Math.PI * 2
  ) {
    super();

    radialSegments = Math.floor(radialSegments);
    tubularSegments = Math.floor(tubularSegments);

    // buffers

    const indices = [];
    const vertices = [];
    const normals = [];
    const uvs = [];

    // helper variables

    const center = new Vector3();
    const vertex = new Vector3();
    const normal = new Vector3();

    // generate vertices, normals and uvs

    for (let j = 0; j <= radialSegments; j++) {
      for (let i = 0; i <= tubularSegments; i++) {
        const u = (i / tubularSegments) * arc;
        const v = (j / radialSegments) * Math.PI * 2;

        // vertex

        vertex.x = (radius + tube * Math.cos(v)) * Math.cos(u);
        vertex.y = (radius + tube * Math.cos(v)) * Math.sin(u);
        vertex.z = tube * Math.sin(v);

        vertices.push(vertex.x, vertex.y, vertex.z);

        // normal

        center.x = radius * Math.cos(u);
        center.y = radius * Math.sin(u);
        normal.subVectors(vertex, center).normalize();

        normals.push(normal.x, normal.y, normal.z);

        // uv

        uvs.push(i / tubularSegments);
        uvs.push(j / radialSegments);
      }
    }

    // generate indices

    for (let j = 1; j <= radialSegments; j++) {
      for (let i = 1; i <= tubularSegments; i++) {
        // indices

        const a = (tubularSegments + 1) * j + i - 1;
        const b = (tubularSegments + 1) * (j - 1) + i - 1;
        const c = (tubularSegments + 1) * (j - 1) + i;
        const d = (tubularSegments + 1) * j + i;

        // faces

        indices.push(a, b, d);
        indices.push(b, c, d);
      }
    }

    this.vertices = new Float32Array(vertices);
    this.indices = new Uint16Array(indices);
    this.normals = new Float32Array(normals);
    this.uvs = new Float32Array(uvs);
  }
}