//@ts-check
import { Color } from "potree/rendering/types";
import { SQMeshLineBuffer } from "./buffer";
import { Matrix4, Quaternion, Vector3 } from "potree/mathtypes";
import { Camera } from "potree/rendering/camera";

export class LineRenderer {
    active = true;
    #viewer;

    #lineProgram: WebGLProgram = null;
    #modelMatrixLocation: WebGLUniformLocation = null;
    #thicknessLocation: WebGLUniformLocation = null;
    #diffuseLocation: WebGLUniformLocation = null;

    entryLocationColor = new Color(255.0 / 255.0, 140.0 / 255, 0 / 255.0);

    #imageFrameGeometry: SQMeshLineBuffer;
    #imageFrameThickness = 0.1;

    #beamGeometry: SQMeshLineBuffer;
    #beamThickness = 1.2;

    constructor(viewer) {
      this.#viewer = viewer;

      viewer.initializedPromise.then(() => {
        const gl: WebGL2RenderingContext = this.#viewer.gl;
        this.#lineProgram = this.#viewer.shaderCache.getProgram(gl, "new_line.vert", "new_line.frag");

        this.#modelMatrixLocation = gl.getUniformLocation(this.#lineProgram, "modelViewMatrix");
        this.#thicknessLocation = gl.getUniformLocation(this.#lineProgram, "thickness");
        this.#diffuseLocation = gl.getUniformLocation(this.#lineProgram, "diffuse");
      });

      const frameWidth = 0.56, frameHeight = 0.56;
      this.#imageFrameGeometry = new SQMeshLineBuffer([
        new Vector3(frameWidth, frameHeight, 0),
        new Vector3(frameWidth, frameHeight, 0),
        new Vector3(-frameWidth, frameHeight, 0),
        new Vector3(-frameWidth, frameHeight, 0),

        new Vector3(-frameWidth, -frameHeight, 0),
        new Vector3(-frameWidth, -frameHeight, 0),
        new Vector3(frameWidth, -frameHeight, 0),
        new Vector3(frameWidth, -frameHeight, 0),

        new Vector3(frameWidth, frameHeight, 0),
        new Vector3(frameWidth, frameHeight, 0),
      ]);
      this.#imageFrameGeometry.initializeVertexArrayObject(viewer.gl);

      this.#beamGeometry = new SQMeshLineBuffer([
        new Vector3(0, 0, 3000),
        new Vector3(0, 0, -1000),
      ]);
      this.#beamGeometry.initializeVertexArrayObject(viewer.gl);
    }

    render(gl: WebGL2RenderingContext, camera: Camera) {
      if(!this.active || !this.#viewer.epsgResolved) {
        return;
      }

      const targetLocation = this.#viewer.targetLocation;

      gl.useProgram(this.#lineProgram);
      // Disable culling on lines since they are magically round geometry which while looking flat aren't actually.
      gl.disable(gl.CULL_FACE);
      gl.enable(gl.BLEND);

      // Set the color of the lines.
      const color = this.entryLocationColor;
      gl.uniform4f(this.#diffuseLocation, color.r, color.g, color.b, 0.9);

      const aspectLocation = gl.getUniformLocation(this.#lineProgram, "aspect");
      //@ts-ignore Camera aspect property is not defined properly in camera class :/
      gl.uniform1f(aspectLocation, camera.aspect);

      // Update matrixes.
      const projectionMatrixLocation = gl.getUniformLocation(this.#lineProgram, "projectionMatrix");
      gl.uniformMatrix4fv(projectionMatrixLocation, false, camera.projectionMatrix.elements);

      if(targetLocation?.image_id) {
        // DRAW IMAGE BOX.
        const marked_image = this.#viewer.getImageObjectTool().images.get(targetLocation.image_id);
        if(!!marked_image) {
          gl.uniformMatrix4fv(this.#modelMatrixLocation, false, marked_image.modelViewMatrix.elements);
          gl.uniform1f(this.#thicknessLocation, this.#imageFrameThickness);

          this.drawLines(gl, this.#imageFrameGeometry);
        }
      }

      // Cache GPS to world space conversion into matrix.
      if(targetLocation?.lat && targetLocation?.lng && !targetLocation.cached_pos) {
        const [x, y] = this.#viewer.convertWGS84toLidar(targetLocation.lng, targetLocation.lat, 0);
        const matrix = new Matrix4().compose(new Vector3(x, y, 0), new Quaternion(), new Vector3(1.0, 1.0, 1.0));

        targetLocation.cached_pos = matrix;
      }

      // DRAW LINE
      if(targetLocation.cached_pos) {
        const matrix = new Matrix4().multiplyMatrices(
          camera.matrixWorldInverse,
          targetLocation.cached_pos
        )

        gl.uniformMatrix4fv(this.#modelMatrixLocation, false, matrix.elements);
        gl.uniform1f(this.#thicknessLocation, this.#beamThickness);

        this.drawLines(gl, this.#beamGeometry);
      }

      gl.disable(gl.BLEND);
      gl.enable(gl.CULL_FACE);
    }

    drawLines(gl: WebGL2RenderingContext, geometry: SQMeshLineBuffer) {
      gl.bindVertexArray(geometry.vao);

      gl.drawElements(gl.TRIANGLES, geometry.count, gl.UNSIGNED_SHORT, 0);
    }
  }
