//@ts-check
import { Matrix3, Matrix4, Ray, Sphere, Vector2, Vector3 } from "./mathtypes";
import { Object3D } from "./object3d";
import { BackSide, DoubleSide } from "./rendering/constants";
import { SpriteMaterial } from "./rendering/material";
import { SQBuffer, SQPlaneBuffer } from "./nova_renderer/buffer";


const _intersectionPointWorld = new Vector3();

export function checkIntersection(object, material, raycaster, ray, pA, pB, pC, point) {
    let intersect;

    if (material.side === BackSide) {
      intersect = ray.intersectTriangle(pC, pB, pA, true, point);
    } else {
      intersect = ray.intersectTriangle(
        pA,
        pB,
        pC,
        material.side !== DoubleSide,
        point
      );
    }

    if (intersect === null) return null;

    _intersectionPointWorld.copy(point);
    _intersectionPointWorld.applyMatrix4(object.matrixWorld);

    const distance = raycaster.ray.origin.distanceTo(_intersectionPointWorld);

    if (distance < raycaster.near || distance > raycaster.far) return null;

    return {
      distance: distance,
      point: _intersectionPointWorld.clone(),
      object: object,
    };
}


const _vector1 = /*@__PURE__*/ new Vector3();
const _vector2 = /*@__PURE__*/ new Vector3();
const _normalMatrix = /*@__PURE__*/ new Matrix3();

export class Plane {
  isPlane = true;

  constructor(normal, constant) {
    this.normal = normal ?? new Vector3(1, 0, 0);
    this.constant = constant ?? 0;
  }

  set(normal, constant) {
    this.normal.copy(normal);
    this.constant = constant;

    return this;
  }

  setComponents(x, y, z, w) {
    this.normal.set(x, y, z);
    this.constant = w;

    return this;
  }

  setFromNormalAndCoplanarPoint(normal, point) {
    this.normal.copy(normal);
    this.constant = -point.dot(this.normal);

    return this;
  }

  setFromCoplanarPoints(a, b, c) {
    const normal = _vector1
      .subVectors(c, b)
      .cross(_vector2.subVectors(a, b))
      .normalize();

    this.setFromNormalAndCoplanarPoint(normal, a);

    return this;
  }

  clone() {
    return new this.constructor().copy(this);
  }

  copy(plane) {
    this.normal.copy(plane.normal);
    this.constant = plane.constant;

    return this;
  }

  normalize() {
    // Note: will lead to a divide by zero if the plane is invalid.

    const inverseNormalLength = 1.0 / this.normal.length();
    this.normal.multiplyScalar(inverseNormalLength);
    this.constant *= inverseNormalLength;

    return this;
  }

  negate() {
    this.constant *= -1;
    this.normal.negate();

    return this;
  }

  distanceToPoint(point) {
    return this.normal.dot(point) + this.constant;
  }

  distanceToSphere(sphere) {
    return this.distanceToPoint(sphere.center) - sphere.radius;
  }

  projectPoint(point, target) {
    return target
      .copy(this.normal)
      .multiplyScalar(-this.distanceToPoint(point))
      .add(point);
  }

  intersectLine(line, target) {
    const direction = line.delta(_vector1);

    const denominator = this.normal.dot(direction);

    if (denominator === 0) {
      // line is coplanar, return origin
      if (this.distanceToPoint(line.start) === 0) {
        return target.copy(line.start);
      }

      // Unsure if this is the correct method to handle this case.
      return undefined;
    }

    const t = -(line.start.dot(this.normal) + this.constant) / denominator;

    if (t < 0 || t > 1) {
      return undefined;
    }

    return target.copy(direction).multiplyScalar(t).add(line.start);
  }

  intersectsLine(line) {
    // Note: this tests if a line intersects the plane, not whether it (or its end-points) are coplanar with it.

    const startSign = this.distanceToPoint(line.start);
    const endSign = this.distanceToPoint(line.end);

    return (startSign < 0 && endSign > 0) || (endSign < 0 && startSign > 0);
  }

  intersectsBox(box) {
    return box.intersectsPlane(this);
  }

  intersectsSphere(sphere) {
    return sphere.intersectsPlane(this);
  }

  coplanarPoint(target) {
    return target.copy(this.normal).multiplyScalar(-this.constant);
  }

  applyMatrix4(matrix, optionalNormalMatrix) {
    const normalMatrix =
      optionalNormalMatrix || _normalMatrix.getNormalMatrix(matrix);

    const referencePoint = this.coplanarPoint(_vector1).applyMatrix4(matrix);

    const normal = this.normal.applyMatrix3(normalMatrix).normalize();

    this.constant = -referencePoint.dot(normal);

    return this;
  }

  translate(offset) {
    this.constant -= offset.dot(this.normal);

    return this;
  }

  equals(plane) {
    return (
      plane.normal.equals(this.normal) && plane.constant === this.constant
    );
  }
}

const _sphere$1 = /*@__PURE__*/ new Sphere();
const _vector$5 = /*@__PURE__*/ new Vector3();

export class Frustum {
    /**
   * @param {Plane} [p0]
   * @param {Plane} [p1]
   * @param {Plane} [p2]
   * @param {Plane} [p3]
   * @param {Plane} [p4]
   * @param {Plane} [p5]
   */
    constructor(p0, p1, p2, p3, p4, p5) {
      this.planes = [
        p0 ?? new Plane(),
        p1 ?? new Plane(),
        p2 ?? new Plane(),
        p3 ?? new Plane(),
        p4 ?? new Plane(),
        p5 ?? new Plane(),
      ];
    }

    /**
   * @param {Plane} p0
   * @param {Plane} p1
   * @param {Plane} p2
   * @param {Plane} p3
   * @param {Plane} p4
   * @param {Plane} p5
   */
    set(p0, p1, p2, p3, p4, p5) {
      const planes = this.planes;

      planes[0].copy(p0);
      planes[1].copy(p1);
      planes[2].copy(p2);
      planes[3].copy(p3);
      planes[4].copy(p4);
      planes[5].copy(p5);

      return this;
    }

    clone() {
      return new this.constructor().copy(this);
    }

    copy(frustum) {
      const planes = this.planes;

      for (let i = 0; i < 6; i++) {
        planes[i].copy(frustum.planes[i]);
      }

      return this;
    }

    setFromProjectionMatrix(m) {
      const planes = this.planes;
      const me = m.elements;
      const me0 = me[0],
        me1 = me[1],
        me2 = me[2],
        me3 = me[3];
      const me4 = me[4],
        me5 = me[5],
        me6 = me[6],
        me7 = me[7];
      const me8 = me[8],
        me9 = me[9],
        me10 = me[10],
        me11 = me[11];
      const me12 = me[12],
        me13 = me[13],
        me14 = me[14],
        me15 = me[15];

      planes[0]
        .setComponents(me3 - me0, me7 - me4, me11 - me8, me15 - me12)
        .normalize();
      planes[1]
        .setComponents(me3 + me0, me7 + me4, me11 + me8, me15 + me12)
        .normalize();
      planes[2]
        .setComponents(me3 + me1, me7 + me5, me11 + me9, me15 + me13)
        .normalize();
      planes[3]
        .setComponents(me3 - me1, me7 - me5, me11 - me9, me15 - me13)
        .normalize();
      planes[4]
        .setComponents(me3 - me2, me7 - me6, me11 - me10, me15 - me14)
        .normalize();
      planes[5]
        .setComponents(me3 + me2, me7 + me6, me11 + me10, me15 + me14)
        .normalize();

      return this;
    }

    intersectsObject(object) {
      const geometry = object.geometry;

      if (geometry.boundingSphere === null) geometry.computeBoundingSphere();

      _sphere$1.copy(geometry.boundingSphere).applyMatrix4(object.matrixWorld);

      return this.intersectsSphere(_sphere$1);
    }

    intersectsSprite(sprite) {
      _sphere$1.center.set(0, 0, 0);
      _sphere$1.radius = 0.7071067811865476;
      _sphere$1.applyMatrix4(sprite.matrixWorld);

      return this.intersectsSphere(_sphere$1);
    }

    intersectsSphere(sphere) {
      const planes = this.planes;
      const center = sphere.center;
      const negRadius = -sphere.radius;

      for (let i = 0; i < 6; i++) {
        const distance = planes[i].distanceToPoint(center);

        if (distance < negRadius) {
          return false;
        }
      }

      return true;
    }

    intersectsBox(box) {
      const planes = this.planes;

      for (let i = 0; i < 6; i++) {
        const plane = planes[i];

        // corner at max distance

        _vector$5.x = plane.normal.x > 0 ? box.max.x : box.min.x;
        _vector$5.y = plane.normal.y > 0 ? box.max.y : box.min.y;
        _vector$5.z = plane.normal.z > 0 ? box.max.z : box.min.z;

        if (plane.distanceToPoint(_vector$5) < 0) {
          return false;
        }
      }

      return true;
    }

    containsPoint(point) {
      const planes = this.planes;

      for (let i = 0; i < 6; i++) {
        if (planes[i].distanceToPoint(point) < 0) {
          return false;
        }
      }

      return true;
    }
}

function checkSQBoxIntersection(object, raycaster, ray, pA, pB, pC, point) {
  let intersect;

  intersect = ray.intersectTriangle(
    pA,
    pB,
    pC,
    true,
    point
  );

  if (intersect === null) return null;

  _intersectionPointWorld.copy(point);
  _intersectionPointWorld.applyMatrix4(object.matrixWorld);

  const distance = raycaster.ray.origin.distanceTo(_intersectionPointWorld);

  if (distance < raycaster.near || distance > raycaster.far) return null;

  return {
    distance: distance,
    point: _intersectionPointWorld.clone(),
    object: object,
  };
}

const _intersectPoint = new Vector3();
const _inverseMatrix = new Matrix4();
const _ray = new Ray();
const _sphere = new Sphere();

const _pA = new Vector3();
const _pB = new Vector3();
const _pC = new Vector3();

export class Mesh extends Object3D {
  /**
   * @param {SQBuffer} geometry
   * @param {import("./rendering/material").Material} [material]
   */
  constructor(geometry, material) {
    super();

    this.type = "Mesh";
    this.isMesh = true;

    this.geometry = geometry;
    this.material = material;
  }

  copy(source) {
    Object3D.prototype.copy.call(this, source);

    this.material = source.material;
    this.geometry = source.geometry;

    return this;
  }

  raycast(raycaster, intersects) {
    const geometry = this.geometry;
    const material = this.material;
    const matrixWorld = this.matrixWorld;

    if (material === undefined) return;

    // Checking boundingSphere distance to ray
    if (geometry.boundingSphere === null) geometry.computeBoundingSphere();

    _sphere.copy(geometry.boundingSphere);
    _sphere.applyMatrix4(matrixWorld);

    if (raycaster.ray.intersectsSphere(_sphere) === false) return;

    _inverseMatrix.copy(matrixWorld).invert();
    _ray.copy(raycaster.ray).applyMatrix4(_inverseMatrix);

    // Check boundingBox before continuing
    if (geometry.boundingBox !== null) {
      if (_ray.intersectsBox(geometry.boundingBox) === false) return;
    }

    let intersection;

    if(geometry instanceof SQBuffer) {
      const indices = geometry.indices;
      const vertices = geometry.vertices;

      for (let i = 0; i < indices.length; i += 3) {
        const a = indices[i] * 3;
        const b = indices[i + 1] * 3;
        const c = indices[i + 2] * 3;

        _pA.set(vertices[a],vertices[a + 1], vertices[a + 2]);
        _pB.set(vertices[b],vertices[b + 1], vertices[b + 2]);
        _pC.set(vertices[c],vertices[c + 1], vertices[c + 2]);

        intersection = checkSQBoxIntersection(
          this,
          raycaster,
          _ray,
          _pA,
          _pB,
          _pC,
          _intersectPoint
        );

        if (intersection) {
          intersection.faceIndex = Math.floor(i / 3); // triangle number in indexed buffer semantics
          intersects.push(intersection);
        }
      }
    }
  }
}

let _spriteGeometry;
export class Sprite extends Object3D {
  constructor(material) {
    super();

    this.type = "Sprite";
    this.isSprite = true;

    if (_spriteGeometry === undefined) {
      _spriteGeometry = new SQPlaneBuffer(1.0, 1.0);
    }

    this.geometry = _spriteGeometry;
    this.material = material ?? new SpriteMaterial();

    this.center = new Vector2(0.5, 0.5);
  }

  copy(source) {
    Object3D.prototype.copy.call(this, source);

    if (source.center !== undefined) this.center.copy(source.center);

    this.material = source.material;

    return this;
  }
}
