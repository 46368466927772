//@ts-check

import { Mesh, Plane } from "potree/geometry";
import { Line3, Matrix4, Raycaster, Vector3, Vector4 } from "potree/mathtypes";
import { SQBoxBuffer, SQLineBuffer, SQPlaneBuffer, SQSphereBuffer, SQTorusBuffer } from "potree/nova_renderer/buffer";
import { Object3D, Scene } from "potree/object3d";
import { BackSide } from "potree/rendering/constants";
import { LineBasicMaterial, MeshBasicMaterial } from "potree/rendering/material";
import { mouseToRay, moveTo, projectedRadius } from "potree/utils/utils";
import TWEEN from "tween.js";

export class TransformationTool {
  constructor(viewer) {
    this.viewer = viewer;

    this.viewer.addEventListener("update", this.update.bind(this));

    this.scene = new Scene();

    this.selection = [];
    this.pivot = new Vector3();
    this.dragging = false;

    this.viewer.inputHandler.registerInteractiveScene(this.scene);
    this.viewer.inputHandler.addEventListener("selection_changed", (e) => {
      for (let selected of this.selection) {
        this.viewer.inputHandler.blacklist.delete(selected);
      }

      this.selection = e.selection;

      for (let selected of this.selection) {
        this.viewer.inputHandler.blacklist.add(selected);
      }
    });

    let red = 0xe73100;
    let green = 0x44a24a;
    let blue = 0x2669e7;

    this.activeHandle = null;
    this.scaleHandles = {
      "scale.x+": {
        name: "scale.x+",
        node: new Object3D(),
        color: red,
        alignment: [+1, +0, +0],
      },
      "scale.x-": {
        name: "scale.x-",
        node: new Object3D(),
        color: red,
        alignment: [-1, +0, +0],
      },
      "scale.y+": {
        name: "scale.y+",
        node: new Object3D(),
        color: green,
        alignment: [+0, +1, +0],
      },
      "scale.y-": {
        name: "scale.y-",
        node: new Object3D(),
        color: green,
        alignment: [+0, -1, +0],
      },
      "scale.z+": {
        name: "scale.z+",
        node: new Object3D(),
        color: blue,
        alignment: [+0, +0, +1],
      },
      "scale.z-": {
        name: "scale.z-",
        node: new Object3D(),
        color: blue,
        alignment: [+0, +0, -1],
      },
    };
    this.focusHandles = {
      "focus.x+": {
        name: "focus.x+",
        node: new Object3D(),
        color: red,
        alignment: [+1, +0, +0],
      },
      "focus.x-": {
        name: "focus.x-",
        node: new Object3D(),
        color: red,
        alignment: [-1, +0, +0],
      },
      "focus.y+": {
        name: "focus.y+",
        node: new Object3D(),
        color: green,
        alignment: [+0, +1, +0],
      },
      "focus.y-": {
        name: "focus.y-",
        node: new Object3D(),
        color: green,
        alignment: [+0, -1, +0],
      },
      "focus.z+": {
        name: "focus.z+",
        node: new Object3D(),
        color: blue,
        alignment: [+0, +0, +1],
      },
      "focus.z-": {
        name: "focus.z-",
        node: new Object3D(),
        color: blue,
        alignment: [+0, +0, -1],
      },
    };
    this.translationHandles = {
      "translation.x": {
        name: "translation.x",
        node: new Object3D(),
        color: red,
        alignment: [1, 0, 0],
      },
      "translation.y": {
        name: "translation.y",
        node: new Object3D(),
        color: green,
        alignment: [0, 1, 0],
      },
      "translation.z": {
        name: "translation.z",
        node: new Object3D(),
        color: blue,
        alignment: [0, 0, 1],
      },
    };
    this.rotationHandles = {
      "rotation.x": {
        name: "rotation.x",
        node: new Object3D(),
        color: red,
        alignment: [1, 0, 0],
      },
      "rotation.y": {
        name: "rotation.y",
        node: new Object3D(),
        color: green,
        alignment: [0, 1, 0],
      },
      "rotation.z": {
        name: "rotation.z",
        node: new Object3D(),
        color: blue,
        alignment: [0, 0, 1],
      },
    };
    this.handles = Object.assign(
      {},
      this.scaleHandles,
      //this.focusHandles,
      this.translationHandles,
      this.rotationHandles
    );
    this.pickVolumes = [];

    this.initializeScaleHandles();
    //this.initializeFocusHandles();
    this.initializeTranslationHandles();
    this.initializeRotationHandles();

    let boxFrameGeometry = new SQLineBuffer([
      // bottom
      new Vector3(-0.5, -0.5, 0.5),
      new Vector3(0.5, -0.5, 0.5),
      new Vector3(0.5, -0.5, 0.5),
      new Vector3(0.5, -0.5, -0.5),
      new Vector3(0.5, -0.5, -0.5),
      new Vector3(-0.5, -0.5, -0.5),
      new Vector3(-0.5, -0.5, -0.5),
      new Vector3(-0.5, -0.5, 0.5),
      // top
      new Vector3(-0.5, 0.5, 0.5),
      new Vector3(0.5, 0.5, 0.5),
      new Vector3(0.5, 0.5, 0.5),
      new Vector3(0.5, 0.5, -0.5),
      new Vector3(0.5, 0.5, -0.5),
      new Vector3(-0.5, 0.5, -0.5),
      new Vector3(-0.5, 0.5, -0.5),
      new Vector3(-0.5, 0.5, 0.5),
      // sides
      new Vector3(-0.5, -0.5, 0.5),
      new Vector3(-0.5, 0.5, 0.5),
      new Vector3(0.5, -0.5, 0.5),
      new Vector3(0.5, 0.5, 0.5),
      new Vector3(0.5, -0.5, -0.5),
      new Vector3(0.5, 0.5, -0.5),
      new Vector3(-0.5, -0.5, -0.5),
      new Vector3(-0.5, 0.5, -0.5)
    ]);
    this.frame = new Mesh(
      boxFrameGeometry,
      new LineBasicMaterial({ color: 0xffff00 })
    );
    this.scene.add(this.frame);

    this.scene.updateMatrixWorld();
  }

  initializeScaleHandles() {
    let sgSphere = new SQSphereBuffer(1, 32, 32);
    let sgLowPolySphere = new SQSphereBuffer(1, 16, 16);

    for (const [handleName, handle] of Object.entries(this.scaleHandles)) {
      let node = handle.node;
      this.scene.add(node);
      node.position.set(...handle.alignment).multiplyScalar(0.5);

      let material = new MeshBasicMaterial({
        color: handle.color,
        opacity: 0.6,
        transparent: true,
      });

      let outlineMaterial = new MeshBasicMaterial({
        color: 0x000000,
        side: BackSide,
        opacity: 0.6,
        transparent: true,
      });

      let sphere = new Mesh(sgSphere, material);
      sphere.scale.set(1, 1, 1);
      sphere.name = `${handleName}.handle`;
      node.add(sphere);

      let outline = new Mesh(sgSphere, outlineMaterial);
      outline.scale.set(1.4, 1.4, 1.4);
      outline.name = `${handleName}.outline`;
      sphere.add(outline);

      let pickSphere = new Mesh(sgLowPolySphere, null);
      pickSphere.name = `${handleName}.pick_volume`;
      pickSphere.scale.set(3, 3, 3);
      sphere.add(pickSphere);
      pickSphere.handle = handleName;
      this.pickVolumes.push(pickSphere);

      node.setOpacity = (target) => {
        let opacity = { x: material.opacity };
        let t = new TWEEN.Tween(opacity).to({ x: target }, 100);
        t.onUpdate(() => {
          sphere.visible = opacity.x > 0;
          pickSphere.visible = opacity.x > 0;
          material.opacity = opacity.x;
          outlineMaterial.opacity = opacity.x;
        });
        t.start();
      };

      pickSphere.addEventListener("drag", (e) => this.dragScaleHandle(e));
      pickSphere.addEventListener("drop", (e) => this.dropScaleHandle(e));

      pickSphere.addEventListener("click", (e) => {
        e.consume();
      });
    }
  }

  initializeFocusHandles() {
    let sgPlane = new SQPlaneBuffer(4, 4);
    let sgLowPolySphere = new SQSphereBuffer(1, 16, 16);

    //let texture = loadTexture(`${resourcePath}/icons/eye_2.png`);

    for (const [handleName, handle] of Object.entries(this.focusHandles)) {
      let node = handle.node;
      this.scene.add(node);
      let align = handle.alignment;

      node.lookAt(new Vector3(...align));

      let off = 0.8;
      if (align[0] === 1) {
        node.position.set(1, off, -off).multiplyScalar(0.5);
        node.rotation.z = Math.PI / 2;
      } else if (align[0] === -1) {
        node.position.set(-1, -off, -off).multiplyScalar(0.5);
        node.rotation.z = Math.PI / 2;
      } else if (align[1] === 1) {
        node.position.set(-off, 1, -off).multiplyScalar(0.5);
        node.rotation.set(Math.PI / 2, Math.PI, 0.0);
      } else if (align[1] === -1) {
        node.position.set(off, -1, -off).multiplyScalar(0.5);
        node.rotation.set(Math.PI / 2, 0.0, 0.0);
      } else if (align[2] === 1) {
        node.position.set(off, off, 1).multiplyScalar(0.5);
      } else if (align[2] === -1) {
        node.position.set(-off, off, -1).multiplyScalar(0.5);
      }

      let material = new MeshBasicMaterial({
        color: handle.color,
        opacity: 0,
        transparent: true,
        //map: texture,
      });

      let box = new Mesh(sgPlane, material);
      box.name = `${handleName}.handle`;
      box.scale.set(1.5, 1.5, 1.5);
      box.position.set(0, 0, 0);
      box.visible = false;
      node.add(box);

      let pickSphere = new Mesh(sgLowPolySphere, null);
      pickSphere.name = `${handleName}.pick_volume`;
      pickSphere.scale.set(3, 3, 3);
      box.add(pickSphere);
      pickSphere.handle = handleName;
      this.pickVolumes.push(pickSphere);

      node.setOpacity = (target) => {
        let opacity = { x: material.opacity };
        let t = new TWEEN.Tween(opacity).to({ x: target }, 100);
        t.onUpdate(() => {
          pickSphere.visible = opacity.x > 0;
          box.visible = opacity.x > 0;
          material.opacity = opacity.x;
        });
        t.start();
      };

      pickSphere.addEventListener("mouseup", (e) => {
        e.consume();
      });

      pickSphere.addEventListener("mousedown", (e) => {
        e.consume();
      });

      pickSphere.addEventListener("click", (e) => {
        e.consume();

        let selected = this.selection[0];
        let maxScale = Math.max(...selected.scale.toArray());
        let handleLength = Math.abs(
          selected.scale.dot(new Vector3(...handle.alignment))
        );
        let alignment = new Vector3(...handle.alignment).multiplyScalar(
          (2 * maxScale) / handleLength
        );
        alignment.applyMatrix4(selected.matrixWorld);
        let newCamPos = alignment;
        let newCamTarget = selected.getWorldPosition(new Vector3());

        moveTo(this.viewer.scene, newCamPos, newCamTarget);
      });
    }
  }

  initializeTranslationHandles() {
    let boxGeometry = new SQBoxBuffer(1, 1, 1);

    for (const [handleName, handle] of Object.entries(
      this.translationHandles
    )) {
      let node = handle.node;
      this.scene.add(node);

      let material = new MeshBasicMaterial({
        color: handle.color,
        opacity: 0.6,
        transparent: true,
      });

      let outlineMaterial = new MeshBasicMaterial({
        color: 0x000000,
        side: BackSide,
        opacity: 0.6,
        transparent: true,
      });

      let box = new Mesh(boxGeometry, material);
      box.name = `${handleName}.handle`;
      box.scale.set(0.2, 0.2, 40);
      box.lookAt(new Vector3(...handle.alignment));
      node.add(box);
      handle.translateNode = box;

      let outline = new Mesh(boxGeometry, outlineMaterial);
      outline.name = `${handleName}.outline`;
      outline.scale.set(3, 3, 1.03);
      box.add(outline);

      let pickVolume = new Mesh(boxGeometry, null);
      pickVolume.name = `${handleName}.pick_volume`;
      pickVolume.scale.set(12, 12, 1.1);
      pickVolume.handle = handleName;
      box.add(pickVolume);
      this.pickVolumes.push(pickVolume);

      node.setOpacity = (target) => {
        let opacity = { x: material.opacity };
        let t = new TWEEN.Tween(opacity).to({ x: target }, 100);
        t.onUpdate(() => {
          box.visible = opacity.x > 0;
          pickVolume.visible = opacity.x > 0;
          material.opacity = opacity.x;
          outlineMaterial.opacity = opacity.x;
        });
        t.start();
      };

      pickVolume.addEventListener("drag", (e) => {
        this.dragTranslationHandle(e);
      });
      pickVolume.addEventListener("drop", (e) => {
        this.dropTranslationHandle(e);
      });
    }
  }

  initializeRotationHandles() {
    let adjust = 0.5;
    let torusGeometry = new SQTorusBuffer(
      1,
      adjust * 0.015,
      8,
      64,
      Math.PI / 2
    );
    let outlineGeometry = new SQTorusBuffer(
      1,
      adjust * 0.04,
      8,
      64,
      Math.PI / 2
    );
    let pickGeometry = new SQTorusBuffer(1, adjust * 0.1, 6, 4, Math.PI / 2);

    for (const [handleName, handle] of Object.entries(this.rotationHandles)) {
      let node = handle.node;
      this.scene.add(node);

      let material = new MeshBasicMaterial({
        color: handle.color,
        opacity: 0.6,
        transparent: true,
      });

      let outlineMaterial = new MeshBasicMaterial({
        color: 0x000000,
        side: BackSide,
        opacity: 0.6,
        transparent: true,
        depthWrite: false,
        depthTest: false
      });

      let box = new Mesh(torusGeometry, material);
      box.name = `${handleName}.handle`;
      box.scale.set(20, 20, 20);
      box.lookAt(new Vector3(...handle.alignment));
      node.add(box);
      handle.translateNode = box;

      let outline = new Mesh(outlineGeometry, outlineMaterial);
      outline.name = `${handleName}.outline`;
      outline.scale.set(1, 1, 1);
      box.add(outline);

      let pickVolume = new Mesh(pickGeometry, null);
      pickVolume.name = `${handleName}.pick_volume`;
      pickVolume.scale.set(1, 1, 1);
      pickVolume.handle = handleName;
      box.add(pickVolume);
      this.pickVolumes.push(pickVolume);

      node.setOpacity = (target) => {
        let opacity = { x: material.opacity };
        let t = new TWEEN.Tween(opacity).to({ x: target }, 100);
        t.onUpdate(() => {
          box.visible = opacity.x > 0;
          pickVolume.visible = opacity.x > 0;
          material.opacity = opacity.x;
          outlineMaterial.opacity = opacity.x;
        });
        t.start();
      };

      pickVolume.addEventListener("drag", (e) => {
        this.dragRotationHandle(e);
      });
      pickVolume.addEventListener("drop", (e) => {
        this.dropRotationHandle(e);
      });
    }
  }

  dragRotationHandle(e) {
    let drag = e.drag;
    let handle = this.activeHandle;
    let camera = this.viewer.sceneContext.getActiveCamera();

    if (!handle) {
      return;
    }

    // MARK DATA DIRTY FOR VOLUMES.
    this.viewer.volumeTool.markDirty(this.selection[0]);

    let localNormal = new Vector3(...handle.alignment);
    let n = new Vector3();
    n.copy(
      new Vector4(...localNormal.toArray(), 0).applyMatrix4(
        handle.node.matrixWorld
      )
    );
    n.normalize();

    if (!drag.intersectionStart) {
      drag.intersectionStart = drag.location;
      drag.objectStart = drag.object.getWorldPosition(new Vector3());
      drag.handle = handle;

      let plane = new Plane().setFromNormalAndCoplanarPoint(
        n,
        drag.intersectionStart
      );

      drag.dragPlane = plane;
      drag.pivot = drag.intersectionStart;
    } else {
      handle = drag.handle;
    }

    this.dragging = true;

    let mouse = drag.end;
    const domElement = this.viewer.renderContext.canvas;
    let ray = mouseToRay(
      mouse,
      camera,
      domElement.clientWidth,
      domElement.clientHeight
    );

    let I = ray.intersectPlane(drag.dragPlane, new Vector3());

    if (I) {
      let center = this.scene.getWorldPosition(new Vector3());
      let from = drag.pivot;
      let to = I;

      let v1 = from.clone().sub(center).normalize();
      let v2 = to.clone().sub(center).normalize();

      let angle = Math.acos(v1.dot(v2));
      let sign = Math.sign(v1.cross(v2).dot(n));
      angle = angle * sign;
      if (Number.isNaN(angle)) {
        return;
      }

      let normal = new Vector3(...handle.alignment);
      for (let selection of this.selection) {
        selection.rotateOnAxis(normal, angle);
      }

      drag.pivot = I;
    }
  }

  dropRotationHandle(e) {
    this.dragging = false;
    this.setActiveHandle(null);
  }

  dragTranslationHandle(e) {
    let drag = e.drag;
    let handle = this.activeHandle;
    let camera = this.viewer.sceneContext.getActiveCamera();

    // MARK DATA DIRTY FOR VOLUMES.
    this.viewer.volumeTool.markDirty(this.selection[0]);

    if (!drag.intersectionStart && handle) {
      drag.intersectionStart = drag.location;
      drag.objectStart = drag.object.getWorldPosition(new Vector3());

      let start = drag.intersectionStart;
      let dir = new Vector4(...handle.alignment, 0).applyMatrix4(
        this.scene.matrixWorld
      );
      let end = new Vector3().addVectors(start, dir);
      let line = new Line3(start.clone(), end.clone());
      drag.line = line;

      let camOnLine = line.closestPointToPoint(
        camera.position,
        false,
        new Vector3()
      );
      let normal = new Vector3().subVectors(camera.position, camOnLine);
      let plane = new Plane().setFromNormalAndCoplanarPoint(
        normal,
        drag.intersectionStart
      );
      drag.dragPlane = plane;
      drag.pivot = drag.intersectionStart;
    } else {
      handle = drag.handle;
    }

    this.dragging = true;

    {
      let mouse = drag.end;
      const domElement = this.viewer.renderContext.canvas;
      let ray = mouseToRay(
        mouse,
        camera,
        domElement.clientWidth,
        domElement.clientHeight
      );
      let I = ray.intersectPlane(drag.dragPlane, new Vector3());

      if (I) {
        let iOnLine = drag.line.closestPointToPoint(I, false, new Vector3());

        let diff = new Vector3().subVectors(iOnLine, drag.pivot);

        for (let selection of this.selection) {
          selection.position.add(diff);
        }

        drag.pivot = drag.pivot.add(diff);
      }
    }
  }

  dropTranslationHandle(e) {
    this.dragging = false;
    this.setActiveHandle(null);
  }

  dropScaleHandle(e) {
    this.dragging = false;
    this.setActiveHandle(null);
  }

  dragScaleHandle(e) {
    let drag = e.drag;
    let handle = this.activeHandle;
    let camera = this.viewer.sceneContext.getActiveCamera();

    // MARK DATA DIRTY FOR VOLUMES.
    this.viewer.volumeTool.markDirty(this.selection[0]);

    if (!drag.intersectionStart) {
      drag.intersectionStart = drag.location;
      drag.objectStart = drag.object.getWorldPosition(new Vector3());
      drag.handle = handle;

      let start = drag.intersectionStart;
      let dir = new Vector4(...handle.alignment, 0).applyMatrix4(
        this.scene.matrixWorld
      );
      let end = new Vector3().addVectors(start, dir);
      let line = new Line3(start.clone(), end.clone());
      drag.line = line;

      let camOnLine = line.closestPointToPoint(
        camera.position,
        false,
        new Vector3()
      );
      let normal = new Vector3().subVectors(camera.position, camOnLine);
      let plane = new Plane().setFromNormalAndCoplanarPoint(
        normal,
        drag.intersectionStart
      );
      drag.dragPlane = plane;
      drag.pivot = drag.intersectionStart;
    } else {
      handle = drag.handle;
    }

    this.dragging = true;

    {
      let mouse = drag.end;
      const domElement = this.viewer.renderContext.canvas;
      let ray = mouseToRay(
        mouse,
        camera,
        domElement.clientWidth,
        domElement.clientHeight
      );
      let I = ray.intersectPlane(drag.dragPlane, new Vector3());

      if (I) {
        let iOnLine = drag.line.closestPointToPoint(I, false, new Vector3());
        let direction = handle.alignment.reduce((a, v) => a + v, 0);

        let toObjectSpace = this.selection[0].matrixWorld.clone().invert();
        let iOnLineOS = iOnLine.clone().applyMatrix4(toObjectSpace);
        let pivotOS = drag.pivot.clone().applyMatrix4(toObjectSpace);
        let diffOS = new Vector3().subVectors(iOnLineOS, pivotOS);
        let dragDirectionOS = diffOS.clone().normalize();
        if (iOnLine.distanceTo(drag.pivot) === 0) {
          dragDirectionOS.set(0, 0, 0);
        }
        let dragDirection = dragDirectionOS.dot(
          new Vector3(...handle.alignment)
        );

        let diff = new Vector3().subVectors(iOnLine, drag.pivot);
        let diffScale = new Vector3(...handle.alignment).multiplyScalar(
          diff.length() * direction * dragDirection
        );
        let diffPosition = diff.clone().multiplyScalar(0.5);

        for (let selection of this.selection) {
          selection.scale.add(diffScale);
          selection.scale.x = Math.max(0.1, selection.scale.x);
          selection.scale.y = Math.max(0.1, selection.scale.y);
          selection.scale.z = Math.max(0.1, selection.scale.z);
          selection.position.add(diffPosition);
        }

        drag.pivot.copy(iOnLine);
      }
    }
  }

  setActiveHandle(handle) {
    if (this.dragging || this.activeHandle === handle) {
      return;
    }

    if (this.activeHandle != null) {
      this.activeHandle.node.setOpacity(0.6);
    }
    this.activeHandle = handle;

    if (handle) {
      handle.node.setOpacity(1.0);
    }
  }

  update() {
    if (this.selection.length !== 1) {
      this.scene.visible = false;
      return;
    }

    this.scene.visible = true;

    this.scene.updateMatrix();
    this.scene.updateMatrixWorld();

    const selected = this.selection[0];
    const camera = this.viewer.sceneContext.getActiveCamera();
    const domElement = this.viewer.renderContext.canvas;
    const mouse = this.viewer.inputHandler.mouse;

    let center = selected.boundingBox
      .getCenter(new Vector3())
      .clone()
      .applyMatrix4(selected.matrixWorld);

    this.scene.scale.copy(
      selected.boundingBox.getSize(new Vector3()).multiply(selected.scale)
    );
    this.scene.position.copy(center);
    this.scene.rotation.copy(selected.rotation);

    this.scene.updateMatrixWorld();

    {
      // adjust scale of components
      for (let handle of Object.values(this.handles)) {
        let node = handle.node;

        let handlePos = node.getWorldPosition(new Vector3());
        let distance = handlePos.distanceTo(camera.position);
        let pr = projectedRadius(
          1,
          camera,
          distance,
          domElement.clientWidth,
          domElement.clientHeight
        );

        let ws = node.parent.getWorldScale(new Vector3());

        let s = 7 / pr;
        let scale = new Vector3(s, s, s).divide(ws);

        let rot = new Matrix4().makeRotationFromEuler(node.rotation);
        let rotInv = rot.clone().invert();

        scale.applyMatrix4(rotInv);
        scale.x = Math.abs(scale.x);
        scale.y = Math.abs(scale.y);
        scale.z = Math.abs(scale.z);

        node.scale.copy(scale);
      }
      this.scene.updateMatrixWorld();

      // adjust rotation handles
      if (!this.dragging) {
        let tWorld = this.scene.matrixWorld;
        let tObject = tWorld.clone().invert();
        let camObjectPos = camera
          .getWorldPosition(new Vector3())
          .applyMatrix4(tObject);

        let x = this.rotationHandles["rotation.x"].node.rotation;
        let y = this.rotationHandles["rotation.y"].node.rotation;
        let z = this.rotationHandles["rotation.z"].node.rotation;

        x.order = "ZYX";
        y.order = "ZYX";

        let above = camObjectPos.z > 0;
        let below = !above;
        let PI_HALF = Math.PI / 2;

        if (above) {
          if (camObjectPos.x > 0 && camObjectPos.y > 0) {
            x.x = 1 * PI_HALF;
            y.y = 3 * PI_HALF;
            z.z = 0 * PI_HALF;
          } else if (camObjectPos.x < 0 && camObjectPos.y > 0) {
            x.x = 1 * PI_HALF;
            y.y = 2 * PI_HALF;
            z.z = 1 * PI_HALF;
          } else if (camObjectPos.x < 0 && camObjectPos.y < 0) {
            x.x = 2 * PI_HALF;
            y.y = 2 * PI_HALF;
            z.z = 2 * PI_HALF;
          } else if (camObjectPos.x > 0 && camObjectPos.y < 0) {
            x.x = 2 * PI_HALF;
            y.y = 3 * PI_HALF;
            z.z = 3 * PI_HALF;
          }
        } else if (below) {
          if (camObjectPos.x > 0 && camObjectPos.y > 0) {
            x.x = 0 * PI_HALF;
            y.y = 0 * PI_HALF;
            z.z = 0 * PI_HALF;
          } else if (camObjectPos.x < 0 && camObjectPos.y > 0) {
            x.x = 0 * PI_HALF;
            y.y = 1 * PI_HALF;
            z.z = 1 * PI_HALF;
          } else if (camObjectPos.x < 0 && camObjectPos.y < 0) {
            x.x = 3 * PI_HALF;
            y.y = 1 * PI_HALF;
            z.z = 2 * PI_HALF;
          } else if (camObjectPos.x > 0 && camObjectPos.y < 0) {
            x.x = 3 * PI_HALF;
            y.y = 0 * PI_HALF;
            z.z = 3 * PI_HALF;
          }
        }
      }

      {
        let ray = mouseToRay(
          mouse,
          camera,
          domElement.clientWidth,
          domElement.clientHeight
        );
        let raycaster = new Raycaster(ray.origin, ray.direction);
        let intersects = raycaster.intersectObjects(
          this.pickVolumes.filter((v) => v.visible),
          true
        );

        if (intersects.length > 0) {
          let I = intersects[0];
          let handleName = I.object.handle;
          this.setActiveHandle(this.handles[handleName]);
        } else {
          this.setActiveHandle(null);
        }
      }
    }
  }
}
