import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Box,
  Typography,
  Stack,
  Alert,
} from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { BASE_URI } from "config";
import { Loading } from "react/views";
import { validateEmail } from "utils";

interface ILoginResponse {
  success?: boolean;
  tooManyAttemps?: boolean;
  changePassword?: boolean;
  challenge?: {
    name: string;
    session: string;
    email: string;
  };
}

export default function SignIn({ setLoggedIn }) {
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [signingIn, setSigningIn] = useState<boolean>(false);
  const [messageLevel, setMessageLevel] = useState<
    "error" | "info" | "success" | "warning"
  >("info");

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setSigningIn(true);
    axios
      .post<ILoginResponse>(
        `${BASE_URI}/auth/login`,
        {
          email: data.get("email"),
          password: data.get("password"),
        },
        { withCredentials: true }
      )
      .then((response) => {
        setSigningIn(false);
        if (response.data.success) {
          setLoggedIn(true);
          let redirectLink = "";
          if (searchParams.has("redirect")) {
            redirectLink = searchParams.get("redirect") as string;
            searchParams.delete("redirect");
            setSearchParams(searchParams);
          }
          if (!!redirectLink) {
            window.location.href = redirectLink.replaceAll("$", "&");
            //navigate(redirectLink.replaceAll('$', '&'));
          }
        } else if (response.data.tooManyAttemps) {
          navigate("reset?too_many_attempts=true");
        } else if (
          !!response.data.challenge &&
          response.data.challenge.name === "NEW_PASSWORD_REQUIRED"
        ) {
          navigate(
            `change?session=${response.data.challenge.session}&email=${response.data.challenge.email}`
          );
        }
      })
      .catch((err) => {
        setSigningIn(false);
        setMessage(err.response.data);
        setMessageLevel("error");
      });
  }

  const emailValid = validateEmail(email);

  useEffect(() => {
    axios
      .get(`${BASE_URI}/auth`, { withCredentials: true })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.loggedIn) {
            setLoggedIn(true);
          }
        }
      });
  }, []);

  return (
    <>
      {message && (
        <Alert
          sx={{ mb: 1 }}
          severity={messageLevel}
          onClose={() => {
            setMessage("");
          }}
        >
          {message}
        </Alert>
      )}
      <Alert severity="info">
        Skyqraft has become Arkion. Read more about the rebrand on our website{" "}
        <a href="https://www.arkion.co/resources/the-company-you-knew-as-skyqraft-is-now-arkion">
          arkion.co
        </a>
        .
      </Alert>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          error={!emailValid && email.length > 0}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          endIcon={signingIn && <Loading relative size={20} color="white" />}
          disabled={signingIn || !emailValid}
        >
          {signingIn ? "Signing" : "Sign"} In
        </Button>
        <Stack alignItems="center">
          <Link style={{ color: "#0078EB" }} to="reset">
            Forgot password?
          </Link>
        </Stack>
      </Box>
    </>
  );
}
