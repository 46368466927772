import { EventDispatcher } from "potree/events";
import { Vector2 } from "potree/mathtypes";
import { toast } from "react-toastify";

export class RenderContext extends EventDispatcher {
  #canvas: HTMLCanvasElement;
  gl: WebGL2RenderingContext;

  #size: Vector2;

  constructor(canvas: HTMLCanvasElement, size: Vector2) {
    super();

    this.#canvas = canvas;

    canvas.addEventListener(
      "webglcontextlost",
      (e) => {
        toast.error("WebGL context lost. \u2639");

        const gl = this.gl;
        const error = gl.getError();
        console.log(error);
      },
      false
    );

    const contextAttributes = {
      alpha: true,
      depth: true,
      stencil: false,
      antialias: true,
      preserveDrawingBuffer: true,
      powerPreference: "high-performance",
    };

    const context = canvas.getContext("webgl2", contextAttributes);
    if(context instanceof WebGL2RenderingContext) {
      this.gl = context;
    } else {
      toast.error("Couldn't create WebGL2 context.");
      // TODO: This is worthy of Sentry logging.
    }

    this.#size = size;
  }

  set size(value) {
    if(this.#size != value) {
      this.#size = value;

      this.dispatchEvent({
        type: "render_size_changed",
        width: this.#size.x,
        height: this.#size.y
      });
    }
  }

  get size() {
    return this.#size;
  }

  get canvas() {
    return this.#canvas;
  }
}