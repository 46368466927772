class WorkerPool {
    constructor() {
        this.workers = {};
    }

    getWorker(in_worker) {
        if (!this.workers[in_worker]) {
            this.workers[in_worker] = [];
        }

        if (this.workers[in_worker].length === 0) {
            let worker = new Worker(in_worker, { type: "module" });
            this.workers[in_worker].push(worker);
        }

        let worker = this.workers[in_worker].pop();

        return worker;
    }

    returnWorker(worker_type, worker) {
        this.workers[worker_type].push(worker);
    }
}

export const workerPool = new WorkerPool();