//@ts-check

import { MOUSE } from "potree/constants";
import { EventDispatcher } from "potree/events";
import { Mesh } from "potree/geometry";
import { Vector3 } from "potree/mathtypes";
import { SQLineBuffer, SQSphereBuffer } from "potree/nova_renderer/buffer";
import { Object3D, Scene } from "potree/object3d";
import { TextSprite } from "potree/sprite";
import { LineBasicMaterial, MeshBasicMaterial } from "potree/rendering/material";
import { Color } from "potree/rendering/types";
import { addCommas, getMousePointCloudIntersection, projectedRadius } from "potree/utils/utils";


function createHeightLine() {
  let lineGeometry = new SQLineBuffer([
    new Vector3(0, 0, 0),
    new Vector3(0, 0, 0)
  ]);

  let lineMaterial = new LineBasicMaterial({
    color: 0x00ff00,
    depthTest: false
  });

  const heightEdge = new Mesh(lineGeometry, lineMaterial);
  heightEdge.visible = false;

  return heightEdge;
}

function createHeightLabel() {
  const heightLabel = new TextSprite("");

  heightLabel.setTextColor({ r: 140, g: 250, b: 140, a: 1.0 });
  heightLabel.setBorderColor({ r: 0, g: 0, b: 0, a: 1.0 });
  heightLabel.setBackgroundColor({ r: 0, g: 0, b: 0, a: 1.0 });
  heightLabel.fontsize = 16;
  heightLabel.material.depthTest = false;
  heightLabel.material.opacity = 1;
  heightLabel.visible = false;

  return heightLabel;
}

class Measure extends Object3D {
  #showDistances = true;
  #closed = true;
  #showAngles = false;
  #showHeight = false;
  #showEdges = true;

  heightEdge = createHeightLine();
  heightLabel = createHeightLabel();

  /**
   * @param {SQSphereBuffer} [geometry]
   * @param {Color} [color]
   * @param {Color} [hoverColor]
   */
  constructor(
    geometry,
    color,
    hoverColor
  ) {
    super();

    // @ts-ignore
    this.constructor.counter = (this.constructor.counter ?? 0) + 1;

    // @ts-ignore
    this.name = "Measure_" + this.constructor.counter;
    this.points = [];
    this.maxMarkers = 2;

    this.sphereGeometry = geometry;
    this.color = color;
    this.hoverColor = hoverColor;
    this.sphereMaterial = new MeshBasicMaterial({
      color: this.color,
    });

    this.spheres = [];
    this.edges = [];
    this.edgeLabels = [];

    this.add(this.heightEdge);
    this.add(this.heightLabel);
    this.lengthUnit = undefined;
  }

  addMarker(point) {
    point = { position: point };

    this.points.push(point);

    const sphere = new Mesh(this.sphereGeometry, this.sphereMaterial);

    this.add(sphere);
    this.spheres.push(sphere);

    {
      // edges
      let lineGeometry = new SQLineBuffer([
        new Vector3(0, 0, 0),
        new Vector3(0, 0, 0)
      ]);

      let lineMaterial = new LineBasicMaterial({
        color: 0xff0000,
      });

      lineMaterial.depthTest = false;

      let edge = new Mesh(lineGeometry, lineMaterial);
      edge.visible = true;

      this.add(edge);
      this.edges.push(edge);
    }

    {
      // edge labels
      let edgeLabel = new TextSprite();
      edgeLabel.name = "Edge Label";
      edgeLabel.setBorderColor({ r: 0, g: 0, b: 0, a: 1.0 });
      edgeLabel.setBackgroundColor({ r: 0, g: 0, b: 0, a: 1.0 });
      edgeLabel.material.depthTest = false;
      edgeLabel.visible = false;
      edgeLabel.fontsize = 16;
      this.edgeLabels.push(edgeLabel);
      this.add(edgeLabel);
    }

    {
      // Measure Event Listeners
      let drag = (e) => {
        const intersectedPoint = getMousePointCloudIntersection(
          e.drag.end,
          e.viewer.sceneContext.getActiveCamera(),
          e.viewer,
          e.viewer.sceneContext.pointclouds,
          { pickClipped: true }
        );

        if (intersectedPoint) {
          const i = this.spheres.indexOf(e.drag.object);
          if (i !== -1) {
            let point = this.points[i];

            // loop through current keys and cleanup ones that will be orphaned
            for (const key of Object.keys(point)) {
              if (!intersectedPoint.point[key]) {
                delete point[key];
              }
            }

            for (const key of Object.keys(intersectedPoint.point).filter(
              (e) => e !== "position"
            )) {
              point[key] = intersectedPoint.point[key];
            }

            this.setPosition(i, intersectedPoint.location);
          }
        }
      };
      const mouseover = (e) => e.object.material.color.set(this.color);
      const mouseleave = (e) => e.object.material.color.set(this.hoverColor);

      sphere.addEventListener("drag", drag);
      sphere.addEventListener("mouseover", mouseover);
      sphere.addEventListener("mouseleave", mouseleave);
    }

    this.dispatchEvent({
      type: "marker_added",
      measurement: this,
      sphere: sphere,
    });

    this.setMarker(this.points.length - 1, point);
  }

  /**
   * @param {number} index
   */
  removeMarker(index) {
    this.points.splice(index, 1);

    this.remove(this.spheres[index]);

    let edgeIndex = index === 0 ? 0 : index - 1;
    this.remove(this.edges[edgeIndex]);
    this.edges.splice(edgeIndex, 1);

    this.remove(this.edgeLabels[edgeIndex]);
    this.edgeLabels.splice(edgeIndex, 1);

    this.spheres.splice(index, 1);

    this.update();

    this.dispatchEvent({ type: "marker_removed", measurement: this });
  }

  setMarker(index, point) {
    this.points[index] = point;

    const event = {
      type: "marker_moved",
      measure: this,
      index: index,
      position: point.position.clone(),
    };
    this.dispatchEvent(event);

    this.update();
  }

  /**
   * @param {number} index
   * @param {Vector3} position
   */
  setPosition(index, position) {
    let point = this.points[index];
    point.position.copy(position);

    let event = {
      type: "marker_moved",
      measure: this,
      index: index,
      position: position.clone(),
    };
    this.dispatchEvent(event);

    this.update();
  }

  getArea() {
    let area = 0;
    let j = this.points.length - 1;

    for (let i = 0; i < this.points.length; i++) {
      let p1 = this.points[i].position;
      let p2 = this.points[j].position;
      area += (p2.x + p1.x) * (p1.y - p2.y);
      j = i;
    }

    return Math.abs(area / 2);
  }

  getTotalDistance() {
    if (this.points.length === 0) {
      return 0;
    }

    let distance = 0;

    for (let i = 1; i < this.points.length; i++) {
      let prev = this.points[i - 1].position;
      let curr = this.points[i].position;
      let d = prev.distanceTo(curr);

      distance += d;
    }

    if (this.closed && this.points.length > 1) {
      let first = this.points[0].position;
      let last = this.points[this.points.length - 1].position;
      let d = last.distanceTo(first);

      distance += d;
    }

    return distance;
  }

  getAngleBetweenLines(cornerPoint, point1, point2) {
    let v1 = new Vector3().subVectors(point1.position, cornerPoint.position);
    let v2 = new Vector3().subVectors(point2.position, cornerPoint.position);

    // avoid the error printed by threejs if denominator is 0
    const denominator = Math.sqrt(v1.lengthSq() * v2.lengthSq());
    if (denominator === 0) {
      return 0;
    } else {
      return v1.angleTo(v2);
    }
  }

  getAngle(index) {
    if (this.points.length < 3 || index >= this.points.length) {
      return 0;
    }

    let previous =
      index === 0
        ? this.points[this.points.length - 1]
        : this.points[index - 1];
    let point = this.points[index];
    let next = this.points[(index + 1) % this.points.length];

    return this.getAngleBetweenLines(point, previous, next);
  }

  update() {
    if (this.points.length === 0) {
      return;
    } else if (this.points.length === 1) {
      let point = this.points[0];
      let position = point.position;
      this.spheres[0].position.copy(position);

      return;
    }

    const lastIndex = this.points.length - 1;

    let centroid = new Vector3();
    for (let i = 0; i <= lastIndex; i++) {
      let point = this.points[i];
      centroid.add(point.position);
    }
    centroid.divideScalar(this.points.length);

    for (let i = 0; i <= lastIndex; i++) {
      let index = i;
      let nextIndex = i + 1 > lastIndex ? 0 : i + 1;

      let point = this.points[index];
      let nextPoint = this.points[nextIndex];

      let sphere = this.spheres[index];

      // spheres
      sphere.position.copy(point.position);
      sphere.material.color = this.color;

      {
        // edges
        let edge = this.edges[index];

        edge.material.color = this.color;

        edge.position.copy(point.position);

        edge.geometry.vertices = new Float32Array([
          0,
          0,
          0,
          ...nextPoint.position.clone().sub(point.position).toArray(),
        ]);

        edge.geometry.needsBufferUpdate = true;
        edge.geometry.computeBoundingSphere();
        edge.visible = (index < lastIndex || this.closed) && this.showEdges;
      }

      {
        // edge labels
        const edgeLabel = this.edgeLabels[i];

        let center = new Vector3().add(point.position);
        center.add(nextPoint.position);
        center = center.multiplyScalar(0.5);
        let distance = point.position.distanceTo(nextPoint.position);

        edgeLabel.position.copy(center);
        edgeLabel.updateMatrixWorld();

        let suffix = "";
        if (this.lengthUnit != null && this.lengthUnitDisplay != null) {
          distance =
            (distance / this.lengthUnit.unitspermeter) *
            this.lengthUnitDisplay.unitspermeter; //convert to meters then to the display unit
          suffix = this.lengthUnitDisplay.code;
        }

        const txtLength = addCommas(distance.toFixed(2));
        edgeLabel.setText(`${txtLength} ${suffix}`);
        edgeLabel.visible =
          this.showDistances &&
          (index < lastIndex || this.closed) &&
          this.points.length >= 2 &&
          distance > 0;
      }
    }

    {
      // update height stuff
      const heightEdge = this.heightEdge;
      heightEdge.visible = this.showHeight;
      this.heightLabel.visible = this.showHeight;

      if (this.showHeight) {
        let sorted = this.points
          .slice()
          .sort((a, b) => a.position.z - b.position.z);
        let lowPoint = sorted[0].position.clone();
        let highPoint = sorted[sorted.length - 1].position.clone();
        let min = lowPoint.z;
        let max = highPoint.z;
        let height = max - min;

        let start = new Vector3(highPoint.x, highPoint.y, min);
        let end = new Vector3(highPoint.x, highPoint.y, max);

        heightEdge.position.copy(lowPoint);
        heightEdge.updateMatrixWorld();

        heightEdge.geometry.vertices = new Float32Array([
          0,
          0,
          0,
          ...start.clone().sub(lowPoint).toArray(),
          ...start.clone().sub(lowPoint).toArray(),
          ...end.clone().sub(lowPoint).toArray(),
        ]);

        heightEdge.geometry.needsBufferUpdate = true;
        heightEdge.geometry.computeBoundingSphere();

        const heightLabelPosition = start.clone().add(end).multiplyScalar(0.5);
        this.heightLabel.position.copy(heightLabelPosition);

        let suffix = "";
        if (this.lengthUnit != null && this.lengthUnitDisplay != null) {
          height =
            (height / this.lengthUnit.unitspermeter) *
            this.lengthUnitDisplay.unitspermeter; //convert to meters then to the display unit
          suffix = this.lengthUnitDisplay.code;
        }

        const txtHeight = addCommas(height.toFixed(2));
        const msg = `${txtHeight} ${suffix}`;
        this.heightLabel.setText(msg);
      }
    }
  }

  raycast(raycaster, intersects) {
    for (let i = 0; i < this.points.length; i++) {
      let sphere = this.spheres[i];

      sphere.raycast(raycaster, intersects);
    }

    // recalculate distances because they are not necessarely correct
    // for scaled objects.
    // see https://github.com/mrdoob/three.js/issues/5827
    // TODO: remove this once the bug has been fixed
    for (let i = 0; i < intersects.length; i++) {
      let I = intersects[i];
      I.distance = raycaster.ray.origin.distanceTo(I.point);
    }
    intersects.sort(function (a, b) {
      return a.distance - b.distance;
    });
  }

  get showAngles() {
    return this.#showAngles;
  }

  set showAngles(value) {
    this.#showAngles = value;
    this.update();
  }

  get showEdges() {
    return this.#showEdges;
  }

  set showEdges(value) {
    this.#showEdges = value;
    this.update();
  }

  get showHeight() {
    return this.#showHeight;
  }

  set showHeight(value) {
    this.#showHeight = value;
    this.update();
  }

  get closed() {
    return this.#closed;
  }

  set closed(value) {
    this.#closed = value;
    this.update();
  }

  get showDistances() {
    return this.#showDistances;
  }

  set showDistances(value) {
    this.#showDistances = value;
    this.update();
  }
}


export class MeasuringTool extends EventDispatcher {
  sphereGeometry = new SQSphereBuffer(0.2, 10, 10);
  color = new Color(0xff0000);
  hoverColor = new Color(0xff5c5c);

  constructor(viewer) {
    super();

    this.viewer = viewer;

    this.addEventListener("start_inserting_measurement", (e) => {
      this.viewer.dispatchEvent({
        type: "cancel_insertions",
      });
    });

    this.showLabels = true;// true;
    this.scene = new Scene();
    this.scene.name = "scene_measurement";

    this.viewer.inputHandler.registerInteractiveScene(this.scene);

    this.onRemove = (e) => {
      this.scene.remove(e.measurement);
    };
    this.onAdd = (e) => {
      this.scene.add(e.measurement);
    };

    for (const measurement of viewer.sceneContext.measurements) {
      this.onAdd({ measurement: measurement });
    }

    viewer.addEventListener("update", this.update.bind(this));
    viewer.addEventListener(
      "scene_context_changed",
      this.onSceneContextChange.bind(this)
    );

    viewer.sceneContext.addEventListener("measurement_added", this.onAdd);
    viewer.sceneContext.addEventListener("measurement_removed", this.onRemove);
  }

  onSceneContextChange(e) {
    e.sceneContext.addEventListener("measurement_added", this.onAdd);
    e.sceneContext.addEventListener("measurement_removed", this.onRemove);

    this.scene.clear();
  }

  startInsertion(args = {}) {
    const domElement = this.viewer.renderContext.canvas;

    let measure = new Measure(this.sphereGeometry, this.color, this.hoverColor);

    this.dispatchEvent({
      type: "start_inserting_measurement",
      measure: measure,
    });

    // Pick defaults if arg is invalid.
    measure.showDistances = args.showDistances ?? true;
    measure.showHeight = args.showHeight ?? false;
    measure.showEdges = args.showEdges ?? true;
    measure.closed = args.closed ?? false;
    measure.maxMarkers = args.maxMarkers ?? 2;
    measure.name = args.name || "Measurement";

    this.scene.add(measure);

    let cancel = {
      removesMarkers: true,
      callback: null,
      escape_callback: null,
    };

    // TODO OLIVER Measurement mouse interactions.
    let insertionCallback = (e) => {
      if (e.button === 0) {
        if (measure.points.length >= measure.maxMarkers) {
          cancel.callback();
        } else {
          measure.addMarker(
            measure.points[measure.points.length - 1].position.clone()
          );

          this.viewer.inputHandler.startDragging(
            measure.spheres[measure.spheres.length - 1]
          );
        }
      } else if (e.button === MOUSE.RIGHT) {
        cancel.callback();
      }
    };

    cancel.escape_callback = (e) => {
      if (cancel.removesMarkers) {
        this.viewer.sceneContext.removeMeasurement(measure);
      }
      cancel.callback(e);
    };

    cancel.callback = (e) => {
      domElement.removeEventListener("mouseup", insertionCallback, false);
      this.viewer.removeEventListener(
        "cancel_measurement",
        cancel.escape_callback
      );
    };

    this.viewer.addEventListener("cancel_measurement", cancel.escape_callback);
    domElement.addEventListener("mouseup", insertionCallback, false);

    measure.addMarker(new Vector3(0, 0, 0));
    this.viewer.inputHandler.startDragging(
      measure.spheres[measure.spheres.length - 1]
    );

    this.viewer.sceneContext.addMeasurement(measure);

    return measure;
  }

  update() {
    const camera = this.viewer.sceneContext.getActiveCamera();
    const measurements = this.viewer.sceneContext.measurements;

    const renderAreaSize = this.viewer.renderContext.size;
    const clientWidth = renderAreaSize.x;
    const clientHeight = renderAreaSize.y;

    // make size independant of distance
    for (const measure of measurements) {
      measure.lengthUnit = this.viewer.lengthUnit;
      measure.lengthUnitDisplay = this.viewer.lengthUnitDisplay;
      measure.update();

      // spheres
      for (const sphere of measure.spheres) {
        let scale = 0.5;
        sphere.scale.set(scale, scale, scale);
      }

      // labels
      for (const label of measure.edgeLabels) {
        const distance = camera.position.distanceTo(
          label.getWorldPosition(new Vector3())
        );
        const pr = projectedRadius(
          1,
          camera,
          distance,
          clientWidth,
          clientHeight
        );
        let scale = 70 / pr;

        label.scale.set(scale, scale, scale);
        label.updateMatrixWorld();
      }

      // height label
      if (measure.showHeight) {
        const label = measure.heightLabel;

        {
          const distance = label.position.distanceTo(camera.position);
          const pr = projectedRadius(
            1,
            camera,
            distance,
            clientWidth,
            clientHeight
          );
          const scale = 70 / pr;
          label.scale.set(scale, scale, scale);
        }
      }

      if (!this.showLabels) {
        const labels = [...measure.edgeLabels, measure.heightLabel];

        for (const label of labels) {
          label.visible = false;
        }
      }

      measure.updateMatrixWorld();
    }
  }
}