import { EventDispatcher } from "../events";
import * as Utils from "../utils/utils.js";
import { Scene } from "../object3d.js";

export default class BaseControls extends EventDispatcher {
  viewer;
  renderer;

  sceneContext = null;
  scene = new Scene();
  rotationSpeed = 10;
  fadeFactor = 20;

  tweens = [];

  constructor(viewer) {
    super();

    this.viewer = viewer;

    this.rotationSpeed = 10;
    this.fadeFactor = 20;

    this.addEventListener("drag", this.onDrag.bind(this));
    this.addEventListener("drop", this.onDrop.bind(this));
    this.addEventListener("mousewheel", this.onScroll.bind(this));
    this.addEventListener("dblclick", this.onDoubleClick.bind(this));
  }

  onDrag(e) {}

  onDrop(e) {
    this.dispatchEvent({ type: "end" });
  }
  onScroll(e) {}

  onDoubleClick(e) {
    Utils.zoomToLocation(e.mouse, this, false, 2.5);
  }

  setSceneContext(sceneContext) {
    this.sceneContext = sceneContext;
  }

  stop(e) {
    this.stopTweens();
  }
  stopTweens() {
    for (const tween of this.tweens) {
      tween.stop();
    }
    this.tweens = [];
  }
}
