import React from "react";
import {
  Box,
  Link as MUILink,
  Typography,
  Container,
  Paper,
  Stack,
  Divider,
} from "@mui/material";
import { Outlet } from "react-router-dom";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <MUILink color="inherit" href="https://www.arkion.co/">
        Arkion
      </MUILink>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function AuthWrapper() {
  return (
    <Box sx={{ width: "100wh", height: "100%", background: "#EDEDED" }}>
      <Container component="main" maxWidth="xs" style={{ height: "100%" }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          style={{ height: "100%" }}
        >
          <Paper sx={{ p: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src="/logo_center_300px.png" alt="Arkion logo" />
              <Divider
                sx={{
                  width: "100%",
                  my: 2,
                }}
              />

              <Outlet />
            </Box>
          </Paper>
          <Copyright sx={{ mt: 2 }} />
        </Stack>
      </Container>
    </Box>
  );
}
