import { PointCloudMaterial } from "potree/pointcloud/material.js";
import { WebGLBuffer, WebGLRenderer, WebGLTexture } from "../../webgl/webgl.js";
import { ClipTask, ElevationGradientRepeat, PointSizeType } from "../constants";
import { Matrix4 } from "../mathtypes.js";
import { getPointCloudMaterial } from "../pointcloud/octree.js";
import { OrthographicCamera } from "../rendering/camera.js";
import * as Shaders from "../rendering/shaders.js";
import { Shader } from "../rendering/shaders.js";

export default class PointCloudRenderer {
  #buffers = new Map();
  #glTypeMapping = new Map();
  #textures = new Map();
  threeRenderer: WebGLRenderer;
  gl: WebGL2RenderingContext;
  shader: Shader;

  constructor(webGlRender: WebGLRenderer) {
    this.threeRenderer = webGlRender;
    this.gl = this.threeRenderer.getContext();

    this.shader = null;

    this.#glTypeMapping.set(Float32Array, this.gl.FLOAT);
    this.#glTypeMapping.set(Uint8Array, this.gl.UNSIGNED_BYTE);
    this.#glTypeMapping.set(Uint16Array, this.gl.UNSIGNED_SHORT);
  }

  deleteBuffer(geometry) {
    const gl = this.gl;
    const webglBuffer = this.#buffers.get(geometry);
    if (webglBuffer !== null) {
      for (const attributeName in geometry.attributes) {
        gl.deleteBuffer(webglBuffer.vbos.get(attributeName).handle);
      }
      this.#buffers.delete(geometry);
    }
  }

  createBuffer(geometry) {
    const gl = this.gl;
    const webglBuffer = new WebGLBuffer();
    webglBuffer.vao = gl.createVertexArray();
    webglBuffer.numElements = geometry.attributes.position.count;

    gl.bindVertexArray(webglBuffer.vao);

    for (const attributeName in geometry.attributes) {
      const bufferAttribute = geometry.attributes[attributeName];

      const vbo = gl.createBuffer();
      gl.bindBuffer(gl.ARRAY_BUFFER, vbo);
      gl.bufferData(gl.ARRAY_BUFFER, bufferAttribute.array, gl.STATIC_DRAW);

      const normalized = bufferAttribute.normalized;
      const type = this.#glTypeMapping.get(bufferAttribute.array.constructor);

      if (Shaders.attributeLocations[attributeName] !== undefined) {
        const attributeLocation =
          Shaders.attributeLocations[attributeName].location;

        gl.vertexAttribPointer(
          attributeLocation,
          bufferAttribute.itemSize,
          type,
          normalized,
          0,
          0
        );
        gl.enableVertexAttribArray(attributeLocation);
      }

      webglBuffer.vbos.set(attributeName, {
        handle: vbo,
        name: attributeName,
        count: bufferAttribute.count,
        itemSize: bufferAttribute.itemSize,
        type: geometry.attributes.position.array.constructor,
        version: 0,
      });
    }

    gl.bindBuffer(gl.ARRAY_BUFFER, null);
    gl.bindVertexArray(null);

    const disposeHandler = () => {
      this.deleteBuffer(geometry);
      geometry.removeEventListener("dispose", disposeHandler);
    };
    geometry.addEventListener("dispose", disposeHandler);

    return webglBuffer;
  }

  updateBuffer(geometry) {
    const gl = this.gl;

    const webglBuffer = this.#buffers.get(geometry);

    gl.bindVertexArray(webglBuffer.vao);

    for (const attributeName in geometry.attributes) {
      const bufferAttribute = geometry.attributes[attributeName];

      let vbo = null;
      if (!webglBuffer.vbos.has(attributeName)) {
        vbo = gl.createBuffer();

        webglBuffer.vbos.set(attributeName, {
          handle: vbo,
          name: attributeName,
          count: bufferAttribute.count,
          itemSize: bufferAttribute.itemSize,
          type: geometry.attributes.position.array.constructor,
          version: bufferAttribute.version,
        });
      } else {
        vbo = webglBuffer.vbos.get(attributeName).handle;
        webglBuffer.vbos.get(attributeName).version = bufferAttribute.version;
      }

      gl.bindBuffer(gl.ARRAY_BUFFER, vbo);
      gl.bufferData(gl.ARRAY_BUFFER, bufferAttribute.array, gl.STATIC_DRAW);

      if (Shaders.attributeLocations[attributeName] !== undefined) {
        const attributeLocation =
          Shaders.attributeLocations[attributeName].location;
        gl.enableVertexAttribArray(attributeLocation);
      }
    }

    gl.bindBuffer(gl.ARRAY_BUFFER, null);
    gl.bindVertexArray(null);
  }

  renderNodes(octree, nodes, visibilityTextureData, camera, shader, params) {
    const gl = this.gl;

    const material = params.material ? params.material : octree.material;
    const shadowMaps = params.shadowMaps ?? [];
    const view = camera.matrixWorldInverse;

    const worldView = new Matrix4();

    const mat4holder = new Float32Array(16);

    for (const [i, node] of nodes.entries()) {
      const world = node.sceneNode.matrixWorld;
      worldView.multiplyMatrices(view, world);

      if (visibilityTextureData) {
        const vnStart = visibilityTextureData.offsets.get(node);
        shader.setUniform1f("uVNStart", vnStart);
      }

      const level = node.getLevel();

      const lModel = shader.uniformLocations.modelMatrix;
      if (lModel) {
        mat4holder.set(world.elements);
        gl.uniformMatrix4fv(lModel, false, mat4holder);
      }

      const lModelView = shader.uniformLocations.modelViewMatrix;
      mat4holder.set(worldView.elements);

      gl.uniformMatrix4fv(lModelView, false, mat4holder);

      shader.setUniform1f("uLevel", level);
      shader.setUniform1f("uNodeSpacing", node.geometryNode.estimatedSpacing);

      shader.setUniform1f("uPCIndex", i);

      if (shadowMaps.length > 0) {
        const worldViewMatrices = shadowMaps
          .map((sm) => sm.camera.matrixWorldInverse)
          .map((view) => new Matrix4().multiplyMatrices(view, world));

        const flattenedMatrices = [].concat(
          ...worldViewMatrices.map((c) => c.elements)
        );
        const lWorldView = shader.uniformLocations["uShadowWorldView[0]"];
        gl.uniformMatrix4fv(lWorldView, false, flattenedMatrices);
      }

      const geometry = node.geometryNode.geometry;

      if (geometry.attributes["gps-time"]) {
        const attGPS = octree.getAttribute("gps-time");

        const initialRange = attGPS.initialRange;
        const initialRangeSize = initialRange[1] - initialRange[0];

        const globalRange = attGPS.range;
        const globalRangeSize = globalRange[1] - globalRange[0];

        let scale = initialRangeSize / globalRangeSize;
        let offset = -(globalRange[0] - initialRange[0]) / initialRangeSize;

        scale = Number.isNaN(scale) ? 1 : scale;
        offset = Number.isNaN(offset) ? 0 : offset;

        shader.setUniform1f("uGpsScale", scale);
        shader.setUniform1f("uGpsOffset", offset);

        const uFilterGPSTimeClipRange =
          material.uniforms.uFilterGPSTimeClipRange.value;

        const normalizedClipRange = [
          (uFilterGPSTimeClipRange[0] - globalRange[0]) / globalRangeSize,
          (uFilterGPSTimeClipRange[1] - globalRange[0]) / globalRangeSize,
        ];

        shader.setUniform2f("uFilterGPSTimeClipRange", normalizedClipRange);
      }

      let webglBuffer = this.#buffers.get(geometry);
      if (webglBuffer === undefined) {
        webglBuffer = this.createBuffer(geometry);
        this.#buffers.set(geometry, webglBuffer);
      } else {
        for (const attributeName in geometry.attributes) {
          const attribute = geometry.attributes[attributeName];

          if (attribute.version > webglBuffer.vbos.get(attributeName).version) {
            this.updateBuffer(geometry);
            break;
          }
        }
      }

      gl.bindVertexArray(webglBuffer.vao);

      for (const attributeName in geometry.attributes) {
        const vbo = webglBuffer.vbos.get(attributeName);

        if (Shaders.attributeLocations[attributeName] !== undefined) {
          const attributeLocation =
            Shaders.attributeLocations[attributeName].location;

          gl.bindBuffer(gl.ARRAY_BUFFER, vbo.handle);
          gl.enableVertexAttribArray(attributeLocation);
        }
      }

      const numPoints = webglBuffer.numElements;
      gl.drawArrays(gl.POINTS, 0, numPoints);
    }

    gl.bindVertexArray(null);
  }

  renderOctree(
    octree,
    camera,
    target,
    params: {
      // biome-ignore lint/suspicious/noExplicitAny: Unknown type
      nodes?: any;
      material?: PointCloudMaterial;
      // biome-ignore lint/suspicious/noExplicitAny: Unknown type
      vnTextureNodes?: any;
      transparent?: boolean;
      blendFunc?: [number, number];
      depthTest?: boolean;
      depthWrite?: boolean;
    } = {}
  ) {
    const gl = this.gl;

    const nodes = params.nodes ?? octree.visibleNodes;
    const material = params.material ?? getPointCloudMaterial();
    const view = camera.matrixWorldInverse;

    const proj = camera.projectionMatrix;

    const shader = this.shader;
    let visibilityTextureData = null;

    let currentTextureBindingPoint = 0;

    if (material.pointSizeType >= 0) {
      if (
        material.pointSizeType === PointSizeType.ADAPTIVE ||
        material.activeAttributeName === "level of detail"
      ) {
        const vnNodes =
          params.vnTextureNodes != null ? params.vnTextureNodes : nodes;
        visibilityTextureData = octree.computeVisibilityTextureData(
          vnNodes,
          camera
        );

        const vnt = material.visibleNodesTexture;
        const data = vnt.image.data;
        data.set(visibilityTextureData.data);
        vnt.needsUpdate = true;
      }
    }

    {
      const numClipBoxes = material.clipBoxes.length ?? 0;
      const numClipBoxTypes = material.clipBoxTypes.length ?? 0;

      const defines = [
        `#define num_clipboxes ${numClipBoxes}`,
        `#define num_clipboxtypes ${numClipBoxTypes}`,
      ];

      if (octree.pcoGeometry.root.isLoaded()) {
        const attributes = octree.pcoGeometry.root.geometry.attributes;

        if (attributes["gps-time"]) {
          defines.push("#define clip_gps_enabled");
        }

        if (attributes["return number"]) {
          defines.push("#define clip_returnnumber_enabled");
        }

        if (attributes["number of returns"]) {
          defines.push("#define clip_number_of_returns_enabled");
        }

        if (attributes["source id"] || attributes["point source id"]) {
          defines.push("#define clip_point_source_id_enabled");
        }
      }

      const definesString = defines.join("\n");

      const vs = material.vertexShader.replace(
        /(#version .*)/,
        `$1\n${definesString}`
      );
      const fs = material.fragmentShader.replace(
        /(#version .*)/,
        `$1\n${definesString}`
      );

      shader.update(vs, fs);

      material.needsUpdate = false;
    }

    for (const uniform of Object.values(material.uniforms)) {
      // @ts-ignore
      if (uniform.type === "t") {
        // @ts-ignore
        const texture = uniform.value;

        if (!texture) {
          continue;
        }

        let webGLTexture = this.#textures.get(texture);
        if (!webGLTexture) {
          webGLTexture = new WebGLTexture(gl, texture);

          this.#textures.set(texture, webGLTexture);
        }

        webGLTexture.update();
      }
    }

    gl.useProgram(shader.program);

    let transparent = false;
    if (params.transparent !== undefined) {
      transparent = params.transparent && material.opacity < 1;
    } else {
      transparent = material.opacity < 1;
    }

    if (transparent) {
      gl.enable(gl.BLEND);
      gl.blendFunc(gl.SRC_ALPHA, gl.ONE);
      gl.depthMask(false);
      gl.disable(gl.DEPTH_TEST);
    } else {
      gl.disable(gl.BLEND);
      gl.depthMask(true);
      gl.enable(gl.DEPTH_TEST);
    }

    if (params.blendFunc !== undefined) {
      gl.enable(gl.BLEND);
      gl.blendFunc(...params.blendFunc);
    }

    if (params.depthTest !== undefined) {
      if (params.depthTest === true) {
        gl.enable(gl.DEPTH_TEST);
      } else {
        gl.disable(gl.DEPTH_TEST);
      }
    }

    if (params.depthWrite !== undefined) {
      gl.depthMask(params.depthWrite === true);
    }

    {
      // UPDATE UNIFORMS
      shader.setUniformMatrix4("projectionMatrix", proj);
      shader.setUniformMatrix4("viewMatrix", view);

      const screenWidth = target ? target.width : material.screenWidth;
      const screenHeight = target ? target.height : material.screenHeight;

      shader.setUniform1f("uScreenWidth", screenWidth);
      shader.setUniform1f("uScreenHeight", screenHeight);
      shader.setUniform1f("fov", (Math.PI * camera.fov) / 180);
      shader.setUniform1f("near", camera.near);
      shader.setUniform1f("far", camera.far);

      shader.setUniformBoolean(
        "uUseOrthographicCamera",
        camera instanceof OrthographicCamera
      );
      shader.setUniform1f("uOrthoWidth", camera.right - camera.left);

      if (material.clipBoxes.length === 0) {
        shader.setUniform1i("clipTask", ClipTask.NONE);
      } else {
        shader.setUniform1i("clipTask", material.clipTask);
      }

      shader.setUniform1i("clipMethod", material.clipMethod);

      if (material.clipBoxes && material.clipBoxes.length > 0) {
        const lClipBoxes = shader.uniformLocations["clipBoxes[0]"];
        gl.uniformMatrix4fv(
          lClipBoxes,
          false,
          material.uniforms.clipBoxes.value
        );
      }

      if (material.clipBoxTypes && material.clipBoxTypes.length > 0) {
        const lClipBoxTypes = shader.uniformLocations["clipBoxTypes[0]"];

        gl.uniform1iv(lClipBoxTypes, material.uniforms.clipBoxTypes);
      }

      shader.setUniform1f("size", material.size);
      shader.setUniform1f("maxSize", material.uniforms.maxSize.value);
      shader.setUniform1f("minSize", material.uniforms.minSize.value);

      shader.setUniform1f("uOctreeSpacing", material.spacing);
      shader.setUniform1f("uOctreeSize", material.uniforms.octreeSize.value);

      shader.setUniform3f("uColor", material.color.toArray());
      shader.setUniform1f("uOpacity", material.opacity);

      shader.setUniform2f("elevationRange", material.elevationRange);
      shader.setUniform2f("intensityRange", material.intensityRange);

      shader.setUniform3f("uIntensity_gbc", [
        material.intensityGamma,
        material.intensityBrightness,
        material.intensityContrast,
      ]);

      shader.setUniform3f("uRGB_gbc", [
        material.rgbGamma,
        material.rgbBrightness,
        material.rgbContrast,
      ]);

      shader.setUniform1f("uTransition", material.transition);
      shader.setUniform1f("wRGB", material.weightRGB);
      shader.setUniform1f("wIntensity", material.weightIntensity);
      shader.setUniform1f("wElevation", material.weightElevation);
      shader.setUniform1f("wClassification", material.weightClassification);
      shader.setUniform1f("wUserData", material.weightUserData);
      shader.setUniform1f("wReturnNumber", material.weightReturnNumber);
      shader.setUniform1f("wSourceID", material.weightSourceID);

      shader.setUniformBoolean(
        "backfaceCulling",
        material.uniforms.backfaceCulling.value
      );

      const vnWebGLTexture = this.#textures.get(material.visibleNodesTexture);
      if (vnWebGLTexture) {
        shader.setUniform1i("visibleNodesTexture", currentTextureBindingPoint);
        gl.activeTexture(gl.TEXTURE0 + currentTextureBindingPoint);
        gl.bindTexture(vnWebGLTexture.target, vnWebGLTexture.id);
        currentTextureBindingPoint++;
      }

      const gradientTexture = this.#textures.get(material.gradientTexture);
      shader.setUniform1i("gradient", currentTextureBindingPoint);
      gl.activeTexture(gl.TEXTURE0 + currentTextureBindingPoint);
      gl.bindTexture(gradientTexture.target, gradientTexture.id);

      const repeat = material.elevationGradientRepeat;
      if (repeat === ElevationGradientRepeat.REPEAT) {
        gl.texParameteri(gradientTexture.target, gl.TEXTURE_WRAP_S, gl.REPEAT);
        gl.texParameteri(gradientTexture.target, gl.TEXTURE_WRAP_T, gl.REPEAT);
      } else if (repeat === ElevationGradientRepeat.MIRRORED_REPEAT) {
        gl.texParameteri(
          gradientTexture.target,
          gl.TEXTURE_WRAP_S,
          gl.MIRRORED_REPEAT
        );
        gl.texParameteri(
          gradientTexture.target,
          gl.TEXTURE_WRAP_T,
          gl.MIRRORED_REPEAT
        );
      } else {
        gl.texParameteri(
          gradientTexture.target,
          gl.TEXTURE_WRAP_S,
          gl.CLAMP_TO_EDGE
        );
        gl.texParameteri(
          gradientTexture.target,
          gl.TEXTURE_WRAP_T,
          gl.CLAMP_TO_EDGE
        );
      }
      currentTextureBindingPoint++;

      const classificationTexture = this.#textures.get(
        material.classificationTexture
      );
      shader.setUniform1i("classificationLUT", currentTextureBindingPoint);
      gl.activeTexture(gl.TEXTURE0 + currentTextureBindingPoint);
      gl.bindTexture(classificationTexture.target, classificationTexture.id);
      currentTextureBindingPoint++;

      const clearanceTexture = this.#textures.get(material.clearanceTexture);
      shader.setUniform1i("clearanceLUT", currentTextureBindingPoint);
      gl.activeTexture(gl.TEXTURE0 + currentTextureBindingPoint);
      gl.bindTexture(clearanceTexture.target, clearanceTexture.id);
      currentTextureBindingPoint++;

      const lclassificationFlags =
        shader.uniformLocations["classificationFlags[0]"];
      gl.uniform1iv(lclassificationFlags, material.classificationFlags);

      const volumeTexture = this.#textures.get(material.volumeTexture);
      shader.setUniform1i("volumeLUT", currentTextureBindingPoint);
      gl.activeTexture(gl.TEXTURE0 + currentTextureBindingPoint);
      gl.bindTexture(volumeTexture.target, volumeTexture.id);
      currentTextureBindingPoint++;

      {
        const uFilterReturnNumberRange =
          material.uniforms.uFilterReturnNumberRange.value;
        const uFilterNumberOfReturnsRange =
          material.uniforms.uFilterNumberOfReturnsRange.value;
        const uFilterPointSourceIDClipRange =
          material.uniforms.uFilterPointSourceIDClipRange.value;

        shader.setUniform2f(
          "uFilterReturnNumberRange",
          uFilterReturnNumberRange
        );
        shader.setUniform2f(
          "uFilterNumberOfReturnsRange",
          uFilterNumberOfReturnsRange
        );
        shader.setUniform2f(
          "uFilterPointSourceIDClipRange",
          uFilterPointSourceIDClipRange
        );
      }
    }

    this.renderNodes(
      octree,
      nodes,
      visibilityTextureData,
      camera,
      shader,
      params
    );

    gl.activeTexture(gl.TEXTURE2);
    gl.bindTexture(gl.TEXTURE_2D, null);
    gl.activeTexture(gl.TEXTURE0);
  }

  prepareOctrees() {
    // UPDATE SHADER AND TEXTURES
    if (!this.shader) {
      const pcMaterial = getPointCloudMaterial();
      const [vs, fs] = [pcMaterial.vertexShader, pcMaterial.fragmentShader];
      this.shader = new Shader(this.gl, "pointcloud", vs, fs);
    }
  }

  render(pointclouds, camera, target = null, params = {}) {
    const gl = this.gl;

    // PREPARE
    if (target != null) {
      this.threeRenderer.setRenderTarget(target);
    }

    // RENDER (point cloud nodes)
    this.prepareOctrees();

    for (const octree of pointclouds) {
      this.renderOctree(octree, camera, target, params);
    }

    // CLEANUP
    gl.activeTexture(gl.TEXTURE1);
    gl.bindTexture(gl.TEXTURE_2D, null);
    gl.bindBuffer(gl.ARRAY_BUFFER, null);
    gl.bindVertexArray(null);

    this.threeRenderer.resetState();
  }
}
