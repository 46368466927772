import { EventDispatcher } from "potree/events";
import { Box3, Matrix3, Matrix4, Sphere, Vector3 } from "potree/mathtypes";
import { arrayMax, generateUUID } from "potree/utils/math";
import { BufferAttribute, Float32BufferAttribute, Uint16BufferAttribute, Uint32BufferAttribute } from "./bufferattribute";

const _m1$3 = new Matrix4();
const _offset = new Vector3();

let _bufferGeometryId = 1; // BufferGeometry uses odd numbers as Id
const _box$2 = new Box3();
const _vector$4 = new Vector3();

export class BufferGeometry extends EventDispatcher {
  isBufferGeometry = true;
  constructor() {
    super();
      Object.defineProperty(this, "id", { value: (_bufferGeometryId += 2) });

      this.uuid = generateUUID();

      this.name = "";
      this.type = "BufferGeometry";

      this.index = null;
      this.attributes = {};

      this.groups = [];

      this.boundingBox = null;
      this.boundingSphere = null;

      this.drawRange = { start: 0, count: Infinity };

      this.userData = {};
  }

  getIndex() {
      return this.index;
    }

    setIndex(index) {
      if (Array.isArray(index)) {
        this.index = new (
          arrayMax(index) > 65535
            ? Uint32BufferAttribute
            : Uint16BufferAttribute
        )(index, 1);
      } else {
        this.index = index;
      }

      return this;
    }

    getAttribute(name) {
      return this.attributes[name];
    }

    setAttribute(name, attribute) {
      this.attributes[name] = attribute;

      return this;
    }

    deleteAttribute(name) {
      delete this.attributes[name];

      return this;
    }

    hasAttribute(name) {
      return this.attributes[name] !== undefined;
    }

    addGroup(start, count, materialIndex = 0) {
      this.groups.push({
        start: start,
        count: count,
        materialIndex: materialIndex,
      });
    }

    clearGroups() {
      this.groups = [];
    }

    setDrawRange(start, count) {
      this.drawRange.start = start;
      this.drawRange.count = count;
    }

    applyMatrix4(matrix) {
      const position = this.attributes.position;

      if (position !== undefined) {
        position.applyMatrix4(matrix);

        position.needsUpdate = true;
      }

      const normal = this.attributes.normal;

      if (normal !== undefined) {
        const normalMatrix = new Matrix3().getNormalMatrix(matrix);

        normal.applyNormalMatrix(normalMatrix);

        normal.needsUpdate = true;
      }

      const tangent = this.attributes.tangent;

      if (tangent !== undefined) {
        tangent.transformDirection(matrix);

        tangent.needsUpdate = true;
      }

      if (this.boundingBox !== null) {
        this.computeBoundingBox();
      }

      if (this.boundingSphere !== null) {
        this.computeBoundingSphere();
      }

      return this;
    }

    rotateX(angle) {
      // rotate geometry around world x-axis
      _m1$3.makeRotationX(angle);
      this.applyMatrix4(_m1$3);

      return this;
    }

    rotateY(angle) {
      // rotate geometry around world y-axis
      _m1$3.makeRotationY(angle);
      this.applyMatrix4(_m1$3);

      return this;
    }

    rotateZ(angle) {
      // rotate geometry around world z-axis
      _m1$3.makeRotationZ(angle);
      this.applyMatrix4(_m1$3);

      return this;
    }

    translate(x, y, z) {
      // translate geometry
      _m1$3.makeTranslation(x, y, z);
      this.applyMatrix4(_m1$3);

      return this;
    }

    scale(x, y, z) {
      // scale geometry
      _m1$3.makeScale(x, y, z);
      this.applyMatrix4(_m1$3);

      return this;
    }

    center() {
      this.computeBoundingBox();

      this.boundingBox.getCenter(_offset).negate();

      this.translate(_offset.x, _offset.y, _offset.z);

      return this;
    }

    setFromObject(object) {
      const geometry = object.geometry;

      if (object.isPoints || object.isLine) {
        const positions = new Float32BufferAttribute(
          geometry.vertices.length * 3,
          3
        );
        const colors = new Float32BufferAttribute(
          geometry.colors.length * 3,
          3
        );

        this.setAttribute(
          "position",
          positions.copyVector3sArray(geometry.vertices)
        );
        this.setAttribute("color", colors.copyColorsArray(geometry.colors));

        if (
          geometry.lineDistances &&
          geometry.lineDistances.length === geometry.vertices.length
        ) {
          const lineDistances = new Float32BufferAttribute(
            geometry.lineDistances.length,
            1
          );

          this.setAttribute(
            "lineDistance",
            lineDistances.copyArray(geometry.lineDistances)
          );
        }

        if (geometry.boundingSphere !== null) {
          this.boundingSphere = geometry.boundingSphere.clone();
        }

        if (geometry.boundingBox !== null) {
          this.boundingBox = geometry.boundingBox.clone();
        }
      } else if (object.isMesh) {
        if (geometry && geometry.isGeometry) {
          this.fromGeometry(geometry);
        }
      }

      return this;
    }

    setFromPoints(points) {
      const position = [];

      for (let i = 0, l = points.length; i < l; i++) {
        const point = points[i];
        position.push(point.x, point.y, point.z || 0);
      }

      this.setAttribute("position", new Float32BufferAttribute(position, 3));

      return this;
    }

    computeBoundingBox() {
      if (this.boundingBox === null) {
        this.boundingBox = new Box3();
      }

      const position = this.attributes.position;
      if (position !== undefined) {
        this.boundingBox.setFromBufferAttribute(position);
      } else {
        this.boundingBox.makeEmpty();
      }

      if (isNaN(this.boundingBox.min.x) || isNaN(this.boundingBox.min.y) || isNaN(this.boundingBox.min.z)) {
        console.error(
          'THREE.BufferGeometry.computeBoundingBox(): Computed min/max have NaN values. The "position" attribute is likely to have NaN values.',
          this
        );
      }
    }

    computeBoundingSphere() {
      if (this.boundingSphere === null) {
        this.boundingSphere = new Sphere();
      }

      const position = this.attributes.position;

      if (position) {
        // first, find the center of the bounding sphere
        const center = this.boundingSphere.center;

        _box$2.setFromBufferAttribute(position);

        _box$2.getCenter(center);

        // second, try to find a boundingSphere with a radius smaller than the
        // boundingSphere of the boundingBox: sqrt(3) smaller in the best case

        let maxRadiusSq = 0;

        for (let i = 0, il = position.count; i < il; i++) {
          _vector$4.fromBufferAttribute(position, i);

          maxRadiusSq = Math.max(
            maxRadiusSq,
            center.distanceToSquared(_vector$4)
          );
        }

        this.boundingSphere.radius = Math.sqrt(maxRadiusSq);

        if (isNaN(this.boundingSphere.radius)) {
          console.error(
            'THREE.BufferGeometry.computeBoundingSphere(): Computed radius is NaN. The "position" attribute is likely to have NaN values.',
            this
          );
        }
      }
    }

    computeVertexNormals() {
      const index = this.index;
      const positionAttribute = this.getAttribute("position");

      if (positionAttribute !== undefined) {
        let normalAttribute = this.getAttribute("normal");

        if (normalAttribute === undefined) {
          normalAttribute = new BufferAttribute(new Float32Array(positionAttribute.count * 3), 3);
          this.setAttribute("normal", normalAttribute);
        } else {
          // reset existing normals to zero
          for (let i = 0, il = normalAttribute.count; i < il; i++) {
            normalAttribute.setXYZ(i, 0, 0, 0);
          }
        }

        const pA = new Vector3(),
          pB = new Vector3(),
          pC = new Vector3();
        const nA = new Vector3(),
          nB = new Vector3(),
          nC = new Vector3();
        const cb = new Vector3(),
          ab = new Vector3();

        // indexed elements

        if (index) {
          for (let i = 0, il = index.count; i < il; i += 3) {
            const vA = index.getX(i + 0);
            const vB = index.getX(i + 1);
            const vC = index.getX(i + 2);

            pA.fromBufferAttribute(positionAttribute, vA);
            pB.fromBufferAttribute(positionAttribute, vB);
            pC.fromBufferAttribute(positionAttribute, vC);

            cb.subVectors(pC, pB);
            ab.subVectors(pA, pB);
            cb.cross(ab);

            nA.fromBufferAttribute(normalAttribute, vA);
            nB.fromBufferAttribute(normalAttribute, vB);
            nC.fromBufferAttribute(normalAttribute, vC);

            nA.add(cb);
            nB.add(cb);
            nC.add(cb);

            normalAttribute.setXYZ(vA, nA.x, nA.y, nA.z);
            normalAttribute.setXYZ(vB, nB.x, nB.y, nB.z);
            normalAttribute.setXYZ(vC, nC.x, nC.y, nC.z);
          }
        } else {
          // non-indexed elements (unconnected triangle soup)
          for (let i = 0, il = positionAttribute.count; i < il; i += 3) {
            pA.fromBufferAttribute(positionAttribute, i + 0);
            pB.fromBufferAttribute(positionAttribute, i + 1);
            pC.fromBufferAttribute(positionAttribute, i + 2);

            cb.subVectors(pC, pB);
            ab.subVectors(pA, pB);
            cb.cross(ab);

            normalAttribute.setXYZ(i + 0, cb.x, cb.y, cb.z);
            normalAttribute.setXYZ(i + 1, cb.x, cb.y, cb.z);
            normalAttribute.setXYZ(i + 2, cb.x, cb.y, cb.z);
          }
        }

        this.normalizeNormals();

        normalAttribute.needsUpdate = true;
      }
    }

    merge(geometry, offset) {
      if (!(geometry && geometry.isBufferGeometry)) {
        console.error("THREE.BufferGeometry.merge(): geometry not an instance of THREE.BufferGeometry.", geometry);
        return;
      }

      if (offset === undefined) {
        offset = 0;

        console.warn(
          "THREE.BufferGeometry.merge(): Overwriting original geometry, starting at offset=0. " +
            "Use BufferGeometryUtils.mergeBufferGeometries() for lossless merge."
        );
      }

      const attributes = this.attributes;

      for (const key in attributes) {
        if (geometry.attributes[key] === undefined) continue;

        const attribute1 = attributes[key];
        const attributeArray1 = attribute1.array;

        const attribute2 = geometry.attributes[key];
        const attributeArray2 = attribute2.array;

        const attributeOffset = attribute2.itemSize * offset;
        const length = Math.min(
          attributeArray2.length,
          attributeArray1.length - attributeOffset
        );

        for (let i = 0, j = attributeOffset; i < length; i++, j++) {
          attributeArray1[j] = attributeArray2[i];
        }
      }

      return this;
    }

    normalizeNormals() {
      const normals = this.attributes.normal;

      for (let i = 0, il = normals.count; i < il; i++) {
        _vector$4.fromBufferAttribute(normals, i);

        _vector$4.normalize();

        normals.setXYZ(i, _vector$4.x, _vector$4.y, _vector$4.z);
      }
    }

    toNonIndexed() {
      function convertBufferAttribute(attribute, indices) {
        const array = attribute.array;
        const itemSize = attribute.itemSize;
        const normalized = attribute.normalized;

        const array2 = new array.constructor(indices.length * itemSize);

        let index = 0,
          index2 = 0;

        for (let i = 0, l = indices.length; i < l; i++) {
          index = indices[i] * itemSize;

          for (let j = 0; j < itemSize; j++) {
            array2[index2++] = array[index++];
          }
        }

        return new BufferAttribute(array2, itemSize, normalized);
      }

      if (this.index === null) {
        console.warn("THREE.BufferGeometry.toNonIndexed(): Geometry is already non-indexed.");
        return this;
      }

      const geometry2 = new BufferGeometry();

      const indices = this.index.array;
      const attributes = this.attributes;

      // attributes

      for (const name in attributes) {
        const attribute = attributes[name];

        const newAttribute = convertBufferAttribute(attribute, indices);

        geometry2.setAttribute(name, newAttribute);
      }

      // groups

      const groups = this.groups;

      for (let i = 0, l = groups.length; i < l; i++) {
        const group = groups[i];
        geometry2.addGroup(group.start, group.count, group.materialIndex);
      }

      return geometry2;
    }

    clone() {
      return new BufferGeometry().copy(this);
    }

    copy(source) {
      // reset
      this.index = null;
      this.attributes = {};
      this.groups = [];
      this.boundingBox = null;
      this.boundingSphere = null;

      // used for storing cloned, shared data
      const data = {};

      // name
      this.name = source.name;

      // index
      const index = source.index;
      if (index !== null) {
        this.setIndex(index.clone(data));
      }

      // attributes
      const attributes = source.attributes;
      for (const name in attributes) {
        const attribute = attributes[name];
        this.setAttribute(name, attribute.clone(data));
      }

      // groups
      const groups = source.groups;
      for (let i = 0, l = groups.length; i < l; i++) {
        const group = groups[i];
        this.addGroup(group.start, group.count, group.materialIndex);
      }

      // bounding box
      const boundingBox = source.boundingBox;
      if (boundingBox !== null) {
        this.boundingBox = boundingBox.clone();
      }

      // bounding sphere
      const boundingSphere = source.boundingSphere;
      if (boundingSphere !== null) {
        this.boundingSphere = boundingSphere.clone();
      }

      // draw range
      this.drawRange.start = source.drawRange.start;
      this.drawRange.count = source.drawRange.count;

      // user data
      this.userData = source.userData;

      return this;
    }

    dispose() {
      this.dispatchEvent({ type: "dispose" });
    }
}

